<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
          <span><span class=" text-emerald-500">>Home</span> > Approval History</span>
          <span>{{ firstname }} {{ surname }}</span>
        </div>
          <div>
          <!-- Timeline -->
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
              <!-- <svg  class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m11.349,24H0V3C0,1.346,1.346,0,3,0h12c1.654,0,3,1.346,3,3v5.059c-.329-.036-.662-.059-1-.059s-.671.022-1,.059V3c0-.552-.448-1-1-1H3c-.552,0-1,.448-1,1v19h7.518c.506.756,1.125,1.429,1.831,2Zm0-14h-7.349v2h5.518c.506-.756,1.125-1.429,1.831-2Zm-7.349,7h4c0-.688.084-1.356.231-2h-4.231v2Zm20,0c0,3.859-3.141,7-7,7s-7-3.141-7-7,3.141-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5ZM14,5H4v2h10v-2Zm5.589,9.692l-3.228,3.175-1.63-1.58-1.393,1.436,1.845,1.788c.314.315.733.489,1.179.489s.865-.174,1.173-.482l3.456-3.399-1.402-1.426Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,10.039c-3.859,0-7,3.14-7,7,0,3.838,3.141,6.961,7,6.961s7-3.14,7-7c0-3.838-3.141-6.961-7-6.961Zm0,11.961c-2.757,0-5-2.226-5-4.961,0-2.757,2.243-5,5-5s5,2.226,5,4.961c0,2.757-2.243,5-5,5Zm1.707-4.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1-1c-.188-.188-.293-.442-.293-.707v-2c0-.552,.447-1,1-1s1,.448,1,1v1.586l.707,.707Zm5.293-10.293v2c0,.552-.447,1-1,1s-1-.448-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1H11c.552,0,1,.448,1,1s-.448,1-1,1H2v9c0,1.654,1.346,3,3,3h4c.552,0,1,.448,1,1s-.448,1-1,1H5c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h1V1c0-.552,.448-1,1-1s1,.448,1,1v1h8V1c0-.552,.447-1,1-1s1,.448,1,1v1h1c2.757,0,5,2.243,5,5Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m6,1c0-.553.448-1,1-1h10c.552,0,1,.447,1,1s-.448,1-1,1H7c-.552,0-1-.447-1-1Zm-2,6h16c.552,0,1-.447,1-1s-.448-1-1-1H4c-.552,0-1,.447-1,1s.448,1,1,1Zm15.5,9h-1.5v-1.5c0-.553-.448-1-1-1s-1,.447-1,1v1.5h-1.5c-.552,0-1,.447-1,1s.448,1,1,1h1.5v1.5c0,.553.448,1,1,1s1-.447,1-1v-1.5h1.5c.552,0,1-.447,1-1s-.448-1-1-1Zm4.5,1c0,3.859-3.14,7-7,7s-7-3.141-7-7,3.14-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5Zm-13,5h-4c-1.654,0-3-1.346-3-3v-4c0-1.654,1.346-3,3-3h4c.552,0,1-.447,1-1s-.448-1-1-1h-4c-2.757,0-5,2.243-5,5v4c0,2.757,2.243,5,5,5h4c.552,0,1-.447,1-1s-.448-1-1-1Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">My Approval History</p>
            </div>
            <!-- {{ requestData }} -->
              <!-- The pending activity approvals -->
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-8 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 sm:gap-y-4">
                <div class=" flex">
                  <input
                    v-model="filter"
                    type="text"
                    placeholder="Search..."
                    required class="shadow-sm bg-gray-50 text-gray-600 w-full text-size border px-2 py-3 border-gray-300  focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 "/>
                </div>
                
                <div class=" overflow-x-auto">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Employee</td>
                        <td class="py-3 px-2 text-left text-white font-bold">Date</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Request Title</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Destination</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Budget Source(s)</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Currency</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Advance Amount (75%)</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Total Amount (100%)</td>
                        <td class="py-3 px-2 border text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in filteredRows" :key="i" class="text-gray-500 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ data.firstname }} {{ data.surname }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.date) }}</td>
                        <td class="py-3 px-2 border text-left">{{data.memo.title}}</td>
                        <td class="py-3 px-2 border text-left">{{ data.memo.destination.name }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.memo.budgetSource.map((data) => data.name).join(',') }}</td>
                        <td class="py-3 px-2 border text-center">{{ data.memo.currency.allData.acronym }}</td>
                        <td class="py-3 px-2 border text-center">{{ formatData(data.budget[0].data[(data.budget[0].data.length -1)].total * 0.75) }}</td>
                        <td class="py-3 px-2 border text-center">{{ formatData(data.budget[0].data[(data.budget[0].data.length -1)].total) }}</td>
                        <td class="py-3 px-2 border text-center space-x-0.5">
                          <button @click.stop="download(data.id, data.memo.title)" class="bg-emerald-600 shadow py-1 px-2 text-white hover:bg-emerald-700">Download</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
              <div class="flex mb-5">
                <div class=" flex mx-auto items-center">
                  <div v-if="startRow !== 0" @click="movePages(-1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer hover:bg-emerald-300 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div v-if="(startRow+rowsPerPage) < requestData.length" @click="movePages(1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer border-l hover:bg-emerald-300 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loader modal -->
    <LoadingModal
        ref="loadingModal"
      />
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
  </div>
</template>

<script>
import Navbar from '@/components/AdminNavbar.vue'
import Sidebar from '@/components/AdminSideBar.vue'
import LoadingModal from './LoadingModal.vue'
import MessageModal from './MessageModal.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { retry } from 'rxjs'

export default {
  computed: {
    ...mapState([
      'id',
      'firstname',
      'surname',
      'token'
    ]),
    filteredRows () {
      return this.requestData.filter((row) => {
        const searchText = this.filter.toLowerCase()

        return JSON.stringify(row).toLowerCase().includes(searchText)
      }).slice(this.startRow, this.startRow + this.rowsPerPage)
    }
  },
  components: {
    Navbar,
    Sidebar,
    LoadingModal,
    MessageModal
  },
  setup () {
    return {
    }
  },
  name: 'approval-history',
  props: {
  },
  data: () => {
    return {
      filter: '',
      startRow: 0,
      rowsPerPage: 10,
      endPageItemIndex: 10,
      showRequests: false,
      expandBody: false,
      requestData: [],
      BASE_URL: process.env.VUE_APP_BASE_URL,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    this.$store.commit('mutateActiveListItemIndex', 7)
    this.$store.commit('mutateSelectedSubMenuItem', 13)
    // getting the pending activity approvals
    this.getRequests()
  },
  methods: {
    // moves the pages of the pagenation
    movePages (amount) {
      var newStartRow = this.startRow + (amount * this.rowsPerPage)
      // only move if its greater than 0
      if (newStartRow >= 0 && newStartRow < this.requestData.length) {
        this.startRow = newStartRow
      }
    },
    download (requestId, title) {
      // download the request in a pdf form
      this.$refs.loadingModal.openLoaderModal()
        const observable = ajax({
          method: 'GET',
          url: this.BASE_URL + '/request/downloadTravelRequest?id=' + requestId,
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          responseType: 'blob'
        })

        const observer = {
          next: (response) => {
            const filename = title.trim().replace(/ /g,'-').toLowerCase() + '-request'
            const blob = response.response
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to download the travel request.'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
          }
        }

        observable.subscribe(observer)
      
    },
    viewApprovedRequest (id) {
      localStorage.setItem('requestId', id)
      this.$router.push('/view-approved-request')
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // getting the pending activity approvals
    getRequests () {
      this.showRequests = false
      const observable = ajax({
        url: this.BASE_URL + '/request/findAll',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          console.log(response)
          const data = response.response
          this.requestData = data
          this.showRequests = true
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
