<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
          <!-- the top route and username grid -->
          <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">>Home</span> > Participants Payments</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <!-- Timeline -->
            <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
              <!-- The chart title -->
              <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
                <!-- <svg  class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
                <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m24,12c0,.553-.448,1-1,1h-10v10c0,.553-.448,1-1,1s-1-.447-1-1v-10H1c-.552,0-1-.447-1-1s.448-1,1-1h10V1c0-.553.448-1,1-1s1,.447,1,1v10h10c.552,0,1,.447,1,1Zm-1,4h-6c-.552,0-1,.447-1,1s.448,1,1,1h6c.552,0,1-.447,1-1s-.448-1-1-1Zm0,4h-6c-.552,0-1,.447-1,1s.448,1,1,1h6c.552,0,1-.447,1-1s-.448-1-1-1ZM1,6h6c.552,0,1-.447,1-1s-.448-1-1-1H1c-.552,0-1,.447-1,1s.448,1,1,1Zm16,0h2v2c0,.553.448,1,1,1s1-.447,1-1v-2h2c.552,0,1-.447,1-1s-.448-1-1-1h-2v-2c0-.553-.448-1-1-1s-1,.447-1,1v2h-2c-.552,0-1,.447-1,1s.448,1,1,1Zm-9.293,10.293c-.391-.391-1.023-.391-1.414,0l-1.793,1.793-1.793-1.793c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l1.793,1.793-1.793,1.793c-.391.391-.391,1.023,0,1.414.195.195.451.293.707.293s.512-.098.707-.293l1.793-1.793,1.793,1.793c.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023,0-1.414l-1.793-1.793,1.793-1.793c.391-.391.391-1.023,0-1.414Z"/></svg> -->
                <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                  <path d="m15,8h-6v-1.5c0-.828.672-1.5,1.5-1.5h3c.828,0,1.5.672,1.5,1.5v1.5Zm-3-4c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2Zm10.5,7h-3c-.828,0-1.5.672-1.5,1.5v1.5h6v-1.5c0-.828-.672-1.5-1.5-1.5Zm-1.5-1c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2Zm-15,.5c0-.828-.672-1.5-1.5-1.5H1.5c-.828,0-1.5.672-1.5,1.5v1.5h6v-1.5Zm-3-2.5c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2Zm21,11.5v4.5H0v-7.5c0-1.378,1.122-2.5,2.5-2.5h4c.171,0,.338.017.5.05v-1.55c0-1.378,1.122-2.5,2.5-2.5h5c1.379,0,2.5,1.122,2.5,2.5v4.55c.162-.033.329-.05.5-.05h4c1.379,0,2.5,1.122,2.5,2.5Zm-15,2.5h6v-9.5c0-.276-.225-.5-.5-.5h-5c-.276,0-.5.224-.5.5v9.5Zm-7,0h5v-5.5c0-.276-.224-.5-.5-.5H2.5c-.276,0-.5.224-.5.5v5.5Zm20-2.5c0-.276-.225-.5-.5-.5h-4c-.275,0-.5.224-.5.5v2.5h5v-2.5Z"/>
                </svg>
                <p class="text-left text-size-md font-medium text-gray-600">Participants Payments</p>
              </div>
              <!-- The activity creation steps -->
              <div class="flex items-center col-span-full mx-5 mt-5">
                <span class="ml-4 mr-0.5 cursor-pointer text-size-sm bg-emerald-600 font-medium ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
                <div class="flex-auto cursor-pointer text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="mx-0.5 cursor-pointer text-size-sm bg-emerald-600 font-medium ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="cursor-pointer animate-pulse mx-0.5 text-size-sm bg-emerald-600 ring-emerald-600 font-medium ring-2 text-white py-0 px-1">3</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="cursor-pointer mx-0.5 text-size-sm ring-emerald-600 font-medium ring-2 text-gray-500 py-0 px-1">4</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="cursor-pointer mx-0.5 text-size-sm ring-emerald-600 font-medium ring-2 text-gray-500 py-0 px-1">5</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="cursor-pointer text-size-sm mr-4 ring-emerald-600 font-medium ring-2 text-gray-500 py-0 px-1">6</span>
              </div>
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-2 sm:gap-y-2">
                <div class="flex justify-between text-center mb-2">
                  <div class="my-auto">
                    <button @click="switchBudget(1)" :class="[ currentParticipantsView == 1 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">Egpaf Participants</button>
                    <button @click="switchBudget(2)" :class="[ currentParticipantsView == 2 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">MoH & Other Participants</button>
                  </div>
                  <div class="my-auto space-x-0.5">
                    <button @click="clearBudget()" class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Clear the Current List</button>
                    <button @click="modalOpener()" class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Add a Participant</button>
                  </div>
                </div>
                <!-- {{ egpafParticipantsData }} -->
                <!-- the egpaf budget -->
                <table v-if="currentParticipantsView === 1" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">EGPAF Employee</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Transportation Refund</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Total</td>
                        <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody v-if="showEgpafParticipants">
                      <tr v-for="(data, i) in egpafParticipantsData" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ data.employee.firstname }} {{ data.employee.surname }}</td>
                        
                        <td v-if="i !== (egpafParticipantsData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.breakfast.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td class="py-3 px-2 border text-left"> {{ data.breakfastDays }} </td>
                        
                        <td v-if="i !== (egpafParticipantsData.length - 1)"  class="py-3 px-2 border text-left">{{ formatData(data.lunch.amount) }}</td>
                        <td v-else  class="py-3 px-2 border text-left"></td>

                        <td class="py-3 px-2 border text-left"> {{ data.lunchDays }} </td>

                        <td v-if="i !== (egpafParticipantsData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.dinner.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td class="py-3 px-2 border text-left"> {{ data.dinnerDays }} </td>

                        <td v-if="i !== (egpafParticipantsData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.Incidental.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>

                        <td class="py-3 px-2 border text-left"> {{ data.incidentalDays }} </td>

                        <td :class="[ i != (egpafParticipantsData.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.transport) }}</td>
                        <td :class="[ i != (egpafParticipantsData.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.total) }}</td>
                        <td  class="py-3 px-2 border text-center flex">
                          <div v-if="i !== (egpafParticipantsData.length - 1)" class="flex mx-auto space-x-4">
                            <svg @click="deleteEgpafItem(i)" class="h-5 w-5 text-red-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <svg @click="loadEditEgpafBudgetModal(i)" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                          </div>
                          <div v-else class="flex mx-auto space-x-4">
                            <svg class="h-5 w-5 text-white fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <svg class="h-5 w-5 text-white fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m21,0H3C1.346,0,0,1.346,0,3v21h24V3c0-1.654-1.346-3-3-3Zm-2.879,10.621l-7.878,7.879h-4.243v-4.242l7.879-7.879c1.133-1.133,3.109-1.133,4.243,0,1.169,1.17,1.169,3.072,0,4.242Zm-3.871,1.043l-4.836,4.836h-1.415v-1.414l4.836-4.836,1.414,1.414Zm2.457-3.871c.39.39.39,1.024,0,1.414l-1.043,1.043-1.414-1.414,1.043-1.043c.377-.379,1.036-.379,1.414,0Z"/></svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                </table>
                 <!-- {{ mohParticipantsData }} -->
                 <!-- the moh budget -->
                 <table v-if="currentParticipantsView === 2" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">MoH/Other Employee</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Transport Refund</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Total</td>
                        <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody v-if="showMoHParticipants">
                      <tr v-for="(data, i) in mohParticipantsData" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ data.employee.firstname }} {{ data.employee.surname }}</td>
                        
                        <td v-if="i !== (mohParticipantsData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.breakfast.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td class="py-3 px-2 border text-left"> {{ data.breakfastDays }} </td>
                        
                        <td v-if="i !== (mohParticipantsData.length - 1)"  class="py-3 px-2 border text-left">{{ formatData(data.lunch.amount) }}</td>
                        <td v-else  class="py-3 px-2 border text-left"></td>

                        <td class="py-3 px-2 border text-left"> {{ data.lunchDays }} </td>

                        <td v-if="i !== (mohParticipantsData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.dinner.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td class="py-3 px-2 border text-left"> {{ data.dinnerDays }} </td>

                        <td v-if="i !== (mohParticipantsData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.Incidental.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>

                        <td class="py-3 px-2 border text-left"> {{ data.incidentalDays }} </td>

                        <td :class="[ i != (mohParticipantsData.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.transport) }}</td>
                        <td :class="[ i != (mohParticipantsData.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.total) }}</td>
                        <td  class="py-3 px-2 border text-center flex">
                          <div v-if="i !== (mohParticipantsData.length - 1)" class="flex mx-auto space-x-4">
                            <svg @click="deleteMoHItem(i)" class="h-5 w-5 text-red-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <svg @click="loadEditMoHBudgetModal(i)" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                          </div>
                          <div v-else class="flex mx-auto space-x-4">
                            <svg class="h-5 w-5 text-white fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <svg class="h-5 w-5 text-white fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m21,0H3C1.346,0,0,1.346,0,3v21h24V3c0-1.654-1.346-3-3-3Zm-2.879,10.621l-7.878,7.879h-4.243v-4.242l7.879-7.879c1.133-1.133,3.109-1.133,4.243,0,1.169,1.17,1.169,3.072,0,4.242Zm-3.871,1.043l-4.836,4.836h-1.415v-1.414l4.836-4.836,1.414,1.414Zm2.457-3.871c.39.39.39,1.024,0,1.414l-1.043,1.043-1.414-1.414,1.043-1.043c.377-.379,1.036-.379,1.414,0Z"/></svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="col-span-full sm:col-span-1">
              <div class="flex-1 mb-10 space-x-0.5">
                <button
                  @click="back()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Back
                </button>
                <button
                  @click="next()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Next
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
      <!-- add egpaf budget modal -->
      <TransitionRoot appear :show="isAddEgpafBudgetOpen" @close="closeAddEgpafBudgetModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center ">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  EGPAF Participants
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                    <!-- egpaf employee -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Select Employees <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                        <CustomDropDown
                          :elementId="'egpafAddEmployees'"
                          :url="'/employee/search'"
                          :persistedSelection="selectedEgpafEmployees"
                          :multipleSelection="true"
                          @selectedItems="(items) => {
                            selectedEgpafEmployees = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['firstname', 'surname', 'position_name']
                          }"
                        />
                        </div>
                      </div>
                    </div>
                    <!-- the breakfast rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Breakfast <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'egpafBreakfastRate'"
                            :persistedSelection="egpafBreakfast"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="breakfastRates"
                            @selectedItems="(items) => {
                              egpafBreakfast = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the lunch rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Lunch <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'egpafLunchRate'"
                            :persistedSelection="egpafLunch"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="lunchRates"
                            @selectedItems="(items) => {
                              egpafLunch = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the dinner rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Dinner <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'egpafDinnerRate'"
                            :persistedSelection="egpafDinner"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="dinnerRates"
                            @selectedItems="(items) => {
                              egpafDinner = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the Incidental rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Incidental <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'egpafIncidentalRate'"
                            :persistedSelection="egpafIncidental"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="incidentalRates"
                            @selectedItems="(items) => {
                              egpafIncidental = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- Egpaf Transportation -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Transportation Refund <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="egpafTransport"
                          required
                          placeholder="Transportation"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Egpaf breakfast Days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Breakfast No Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="egpafBreakfastDays"
                          required
                          placeholder="Transportation"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                    <!-- Egpaf lunch days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Lunch No Of Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="egpafLunchDays"
                          required
                          placeholder="Transportation"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>


                    <!-- Egpaf dinner days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Dinner Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="egpafDinnerDays"
                          required
                          placeholder="Dinner"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>


                    <!-- Egpaf incidental days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Incidental Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="egpafIncidentalDays"
                          required
                          placeholder="Dinner"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddEgpafBudgetModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-600">
                      Cancel
                    </button>
                    <button
                      @click="addEgpafParticipantsData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-600">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- edit egpaf budget modal -->
      <TransitionRoot appear :show="isEditEgpafBudgetOpen" @close="closeEditEgpafParticipantModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Edit EGPAF Participants
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                   <!-- egpaf employee -->
                   <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Select Employees <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'egpafAddEmployees'"
                          :url="'/employee/search'"
                          :persistedSelection="selectedEgpafEmployees"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedEgpafEmployees = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['firstname', 'surname', 'position_name']
                          }"
                        />
                      </div>
                    </div>
                    </div>
                    <!-- the breakfast rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Breakfast <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'egpafBreakfastRate'"
                            :persistedSelection="egpafBreakfast"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="breakfastRates"
                            @selectedItems="(items) => {
                              egpafBreakfast = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the lunch rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Lunch <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'egpafLunchRate'"
                            :persistedSelection="egpafLunch"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="lunchRates"
                            @selectedItems="(items) => {
                              egpafLunch = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the dinner rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Dinner <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'egpafDinnerRate'"
                            :persistedSelection="egpafDinner"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="dinnerRates"
                            @selectedItems="(items) => {
                              egpafDinner = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the Incidental rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Incidental <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'egpafIncidentalRate'"
                            :persistedSelection="egpafIncidental"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="incidentalRates"
                            @selectedItems="(items) => {
                              egpafIncidental = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- Transport refund -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Transport Refund <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="egpafTransport"
                          requiemerald
                          placeholder="Transportation"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                    <!-- Egpaf breakfast Days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Breakfast No Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="egpafBreakfastDays"
                          required
                          placeholder="Transportation"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                    <!-- Egpaf lunch days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Lunch No Of Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="egpafLunchDays"
                          required
                          placeholder="Transportation"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>


                    <!-- Egpaf dinner days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Dinner Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="egpafDinnerDays"
                          required
                          placeholder="Dinner"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>


                    <!-- Egpaf incidental days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Incidental Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="egpafIncidentalDays"
                          required
                          placeholder="Dinner"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeEditEgpafParticipantModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="editEgpafParticipantsData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- add moh budget modal -->
      <TransitionRoot appear :show="isAddMoHBudgetOpen" @close="closeAddMoHBudgetModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center ">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  MoH & Other Participants
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 gap-4 py-4">
                     <!-- Selected employees -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Select Employees <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'egpafAddVenue'"
                          :url="'/participant/search'"
                          :persistedSelection="selectedMoHEmployees"
                          :multipleSelection="true"
                          :triggersAnotherDialog="true"
                          :triggerButtonLabel="'Add Participant'"
                          @selectedItems="(items) => {
                            selectedMoHEmployees = items
                          }"
                          @openAnotherModal ="() => {
                            closeAddMoHBudgetModal()
                            openEditParticipantModal()
                          }"
                          :display="{
                            id: 'id',
                            name: ['firstname', 'surname', 'position', 'stakeholder_name']
                          }"
                        />
                      </div>
                    </div>
                    </div>
                    <!-- the breakfast rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Breakfast <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'mohBreakfastRate'"
                            :persistedSelection="mohBreakfast"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="breakfastRates"
                            @selectedItems="(items) => {
                              mohBreakfast = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the lunch rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Lunch <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'egpafLunchRate'"
                            :persistedSelection="mohLunch"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="lunchRates"
                            @selectedItems="(items) => {
                              mohLunch = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the dinner rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Dinner <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'mohDinnerRate'"
                            :persistedSelection="mohDinner"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="dinnerRates"
                            @selectedItems="(items) => {
                              mohDinner = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                      <!-- the breakfast rate -->
                      <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Incidental <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'mohIncidentalRate'"
                            :persistedSelection="mohIncidental"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="incidentalRates"
                            @selectedItems="(items) => {
                              mohIncidental = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the transport refund -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Transport Refund <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mohTransport"
                          required
                          placeholder="Transport Refund"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                  <!-- MoH breakfast Days -->
                  <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Breakfast No Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mohBreakfastDays"
                          required
                          placeholder="Transportation"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                    <!-- MoH lunch days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Lunch No Of Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mohLunchDays"
                          required
                          placeholder="Transportation"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                    <!-- MoH dinner days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Dinner Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mohDinnerDays"
                          required
                          placeholder="Dinner"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                    <!-- MoH incidental days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Incidental Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mohIncidentalDays"
                          required
                          placeholder="Dinner"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                  </div>
                  
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddMoHBudgetModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-600">
                      Cancel
                    </button>
                    <button
                      @click="addMohParticipantsData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-600">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- edit the moh budget modal -->
      <TransitionRoot appear :show="isEditMoHBudgetOpen" @close="closeEditMoHParticipantModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Edit MoH & Other Participant
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Selected employees -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Select Employees <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'mohEmployees'"
                          :url="'/participant/search'"
                          :persistedSelection="selectedMoHEmployees"
                          :multipleSelection="false"
                          :triggersAnotherDialog="false"
                          :triggerButtonLabel="'Add Participant'"
                          @selectedItems="(items) => {
                            selectedMoHEmployees = items
                          }"
                          @openAnotherModal ="() => {
                            closeAddMoHBudgetModal()
                            openEditParticipantModal()
                          }"
                          :display="{
                            id: 'id',
                            name: ['firstname', 'surname', 'position', 'stakeholder_name']
                          }"
                        />
                      </div>
                    </div>
                    </div>
                    <!-- the breakfast rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Breakfast <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'mohBreakfastRate'"
                            :persistedSelection="mohBreakfast"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="breakfastRates"
                            @selectedItems="(items) => {
                              mohBreakfast = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the lunch rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Lunch <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'egpafLunchRate'"
                            :persistedSelection="mohLunch"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="lunchRates"
                            @selectedItems="(items) => {
                              mohLunch = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the dinner rate -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Dinner <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'mohDinnerRate'"
                            :persistedSelection="mohDinner"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="dinnerRates"
                            @selectedItems="(items) => {
                              mohDinner = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                      <!-- the breakfast rate -->
                      <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Incidental <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'mohIncidentalRate'"
                            :persistedSelection="mohIncidental"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="incidentalRates"
                            @selectedItems="(items) => {
                              mohIncidental = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['formatted_amount', 'location']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the transport refund -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Transport Refund <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mohTransport"
                          required
                          placeholder="Transport Refund"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                  <!-- MoH breakfast Days -->
                  <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Breakfast No Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mohBreakfastDays"
                          required
                          placeholder="Transportation"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                    <!-- MoH lunch days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Lunch No Of Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mohLunchDays"
                          required
                          placeholder="Transportation"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                    <!-- MoH dinner days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Dinner Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mohDinnerDays"
                          required
                          placeholder="Dinner"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>

                    <!-- MoH incidental days -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Incidental Days <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mohIncidentalDays"
                          required
                          placeholder="Dinner"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeEditMoHParticipantModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="editmohParticipantsData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      










      <!-- add unit budget modal -->
      <TransitionRoot appear :show="isAddParticipantModalOpen" @close="closeAddParticipantModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center ">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Add new MoH & Other Participant
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                    <!-- Firstname -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Firstname <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="firstname"
                          required
                          placeholder="Firstname"
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Surname -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Surname <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="surname"
                          placeholder="Surname"
                          required
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Stakeholder  -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Stakeholder Group<span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                        <CustomDropDown
                          :elementId="'otherStakeholder'"
                          :url="'/stakeholder/search'"
                          :persistedSelection="selectedStakeholder"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedStakeholder = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                        </div>
                      </div>
                    </div>
                    <!-- Phone Number -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Phone Number <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="phone"
                          required
                          placeholder="Phone Number"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Sex -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Sex <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <OfflineDropDown
                            :elementId="'mohSex'"
                            :persistedSelection="sex"
                            :buttonLabel="'Select the Rate'"
                            :multipleSelection="false"
                            :dataSource="sexes"
                            @selectedItems="(items) => {
                              sex = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['name']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- Position -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Position <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="position"
                          required
                          placeholder="Position"
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Bank Account -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Bank Account Number <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="bankAccount"
                          required
                          placeholder="Bank Account Number"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Swift Code -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Swift Code <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="swiftCode"
                          required
                          placeholder="Swift Code"
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddParticipantModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-600">
                      Cancel
                    </button>
                    <button
                      @click="submitParticipant()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-600">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- edit the unit budget modal -->
      <TransitionRoot appear :show="isEditParticipantModalOpen" @close="closeEditUnitBudgetModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-4xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Edit Unit Cost Budget
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Date -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="unitDate"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Description -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="unitDescription"
                          placeholder="Description"
                          required
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- the cost  -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Cost <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="unitCost"
                          required
                          placeholder="Item cost"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- unit quantity -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Quantity <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="unitQuantity"
                          required
                          placeholder="Item quantity"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeEditUnitBudgetModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="editUnitBudgetData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>


        <!-- Loader modal -->
        <LoadingModal
        ref="loadingModal"
      />
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />

      <!-- the pop up modal -->
      <PopUp 
        ref="popupRef"
        :message="saveMessage" />


    </div>
  </template>
  
  <script>
  import Navbar from '@/components/Navbar.vue'
  import Sidebar from '@/components/SideBar.vue'
  import LoadingModal from './LoadingModal.vue'
  import MessageModal from './MessageModal.vue'
  import OfflineDropDown from './OfflineDropDown.vue'
  import CustomDropDown from './CustomDropDown.vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  import { mapState } from 'vuex'
  import { ref } from 'vue'
  import { interval, retry } from 'rxjs'
  import { ajax } from 'rxjs/ajax'
  import PopUp from './PopUp.vue'
  
  export default {
    computed: {
      ...mapState([
        'firstname',
        'surname',
        'token',
        'memo',
        'egpafPayments',
        'mohPayments'
      ])
    },
    components: {
      PopUp,
      Navbar,
      Sidebar,
      LoadingModal,
      MessageModal,
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      OfflineDropDown,
      CustomDropDown
    },
    setup () {
      const isAddEgpafBudgetOpen = ref(false)
      const isEditEgpafBudgetOpen = ref(false)

      const isAddMoHBudgetOpen = ref(false)
      const isEditMoHBudgetOpen = ref(false)
      
      const isAddParticipantModalOpen = ref(false)
      const isEditParticipantModalOpen = ref(false)

      return {
        isAddEgpafBudgetOpen,
        isEditEgpafBudgetOpen,
        isAddMoHBudgetOpen,
        isEditMoHBudgetOpen,
        isAddParticipantModalOpen,
        isEditParticipantModalOpen,
        closeAddEgpafBudgetModal () {
          isAddEgpafBudgetOpen.value = false
        },
        openAddEgpafBudgetModal () {
          isAddEgpafBudgetOpen.value = true
        },
        closeEditEgpafParticipantModal () {
          isEditEgpafBudgetOpen.value = false
        },
        openEditEgpafBudgetModal () {
          isEditEgpafBudgetOpen.value = true
        },

        closeAddMoHBudgetModal () {
          isAddMoHBudgetOpen.value = false
        },
        openAddMoHBudgetModal () {
          isAddMoHBudgetOpen.value = true
        },
        closeEditMoHParticipantModal () {
          isEditMoHBudgetOpen.value = false
        },
        openEditMoHBudgetModal () {
          isEditMoHBudgetOpen.value = true
        },

        closeAddParticipantModal () {
          isAddParticipantModalOpen.value = false
        },
        openEditParticipantModal () {
          isAddParticipantModalOpen.value = true
        }
      }
    },
    name: 'participant-payments',
    props: {
    },
    data: () => {
      return {
        isOk: true,
        messageTitle: '',
        message: '',
        date: '',
        description: '',
        noEmployees: 1,
        amount: 0,
        egpafParticipantsData: [],
        mohParticipantsData: [],
        unitBudgetData: [],
        expandBody: false,
        showEgpafParticipants: false,
        showMoHParticipants: false,
        showUnitBudget: false,
        selectedEgpafEditIndex: -1,
        selectedMoHEditIndex: -1,
        selectedUnitEditIndex: -1,
        selectedBudget: {},
        currency: {},
        saveMessage: process.env.VUE_APP_SAVE_MESSAGE,
        budgetTypes: [
          { id: 1, name: 'EGPAF Budget'},
          { id: 2, name: 'MoH & Other Budget' },
          { id: 3, name: 'Other Budget' }
        ],
        BASE_URL: process.env.VUE_APP_BASE_URL,
        // gets the selected budget
        currentParticipantsView: 1,
        // the egpaf modal details
        egpafTransport: 0,
        egpafBreakfast: {},
        egpafLunch: '',
        egpafDinner: '',
        egpafIncidental: '',
        egpafBreakfastDays: 0,
        egpafLunchDays: 0,
        egpafDinnerDays: 0,
        egpafIncidentalDays: 0,
        selectedEgpafEmployees: {},
        // the moh modal details
        mohTransport: 0,
        mohBreakfast: {},
        mohLunch: '',
        mohDinner: '',
        mohIncidental: '',
        mohBreakfastDays: 0,
        mohLunchDays: 0,
        mohDinnerDays: 0,
        mohIncidentalDays: 0,
        selectedMoHEmployees: {},
        // the unit modal details
        unitDate: '',
        unitDescription: '',
        unitCost: '',
        unitQuantity: '',
        // the new moh other participant
        firstname: '',
        surname: '',
        selectedStakeholder: {},
        phone: '',
        sex: {},
        position: '',
        bankAccount: '',
        swiftCode: '',
        sexes: [
          { id: 1, name: 'M' },
          { id: 2, name: 'F' }
        ],
        // the dsa rates
        breakfastRates: [],
        lunchRates: [],
        dinnerRates: [],
        incidentalRates: [],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      }
    },
    mounted () {
      // removing the submenu items selection
      this.$store.commit('mutateActiveListItemIndex', 1)
      this.$store.commit('mutateSelectedSubMenuItem', null)
      // load the stored vuex data
      this.loadVuexData()
      // get the dsa rates
      this.getDsaRates()
      // start saving the data
      this.autosave()
    },
    methods: {
      submitParticipant () {
        // close the add moh participants modal
        this.closeAddParticipantModal()

        // open the loading modal
        this.$refs.loadingModal.openLoaderModal()

        const observable = ajax({
            url: this.BASE_URL + '/participant/insert',
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.token}`
            },
            body: {
              firstname: this.firstname,
              surname: this.surname,
              stakeholder_id: this.selectedStakeholder.id,
              phone: this.phone,
              sex: this.sex.name,
              position: this.position,
              bank_account: this.bankAccount,
              swift_code: this.swiftCode
            }
          })
        const observer = {
          next: (response) => {
            const data = response.response
            const code = data.code

            if (code === 'ER_DUP_ENTRY') {
              this.isOk = true
              this.messageTitle = 'Message'
              
              const sqlMessage = data.sqlMessage

              if (sqlMessage.includes('phone')) {
                this.message = 'A participant with this phone number already exists...'
              } else   if (sqlMessage.includes('account')) {
                this.message = 'A participant with this account number already exists...'
              } else {
                this.message = 'Successfully added the participant'
              }

              this.$refs.messageModal.openMessageModal()

            } else {
              this.isOk = true
              this.messageTitle = 'Message'
              this.message = 'Successfully added the participant...'
              this.$refs.messageModal.openMessageModal()
            }
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to add the participants details please try again later...'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
          }
        }

        observable.subscribe(observer)
      },
      loadVuexData () {
        if (this.egpafPayments !== null) {
          this.showEgpafParticipants = false
          this.egpafParticipantsData = this.egpafPayments
          this.showEgpafParticipants = true
        }
        
        if (this.mohPayments !== null) {
          this.showMoHParticipants = false
          this.mohParticipantsData = this.mohPayments
          this.showMoHParticipants = true
        }

      },
      autosave () {
        this.$refs.popupRef.show()
        const observable = interval(1000)
        const observer = {
          next: () => {
            this.$store.commit('mutateMoHPayments', this.mohParticipantsData)
            this.$store.commit('mutateEgpafPayments', this.egpafParticipantsData)
          },
          error: () => {}
        }
        observable.subscribe(observer)
      },
      switchBudget (index) {
        this.currentParticipantsView = index
      },
      // clears up the egpaf budget modal's fields
      clearEgpafModal () {
        this.egpafTransport = 0
        this.egpafBreakfast = {}
        this.egpafLunch = ''
        this.egpafDinner = ''
        this.egpafIncidental = ''
        this.egpafBreakfastDays = 0
        this.egpafLunchDays = 0
        this.egpafDinnerDays = 0
        this.egpafIncidentalDays = 0
        this.selectedEgpafEmployees = {}
      },
      clearMoHModal () {
        this.mohTransport = 0
        this.mohBreakfast = {}
        this.mohLunch = ''
        this.mohDinner = ''
        this.mohIncidental = ''
        this.mohBreakfastDays = 0
        this.mohLunchDays = 0
        this.mohDinnerDays = 0
        this.mohIncidentalDays = 0
        this.selectedMoHEmployees = {}
      },
      clearUnitModal () {
        this.unitDate = ''
        this.unitDescription = ''
        this.unitCost = ''
        this.unitQuantity = ''
      },
      modalOpener () {
        switch (this.currentParticipantsView) {
            case 1:
              // clear the input data
              this.clearEgpafModal()
              this.openAddEgpafBudgetModal()
              break
            case 2:
              this.clearMoHModal()
              this.openAddMoHBudgetModal()
              break
            default:
              this.clearUnitModal()
              this.openAddUnitBudgetModal()
              // console.log(1, this.currentParticipantsView)
          }
      },
      // get the dsa rates
      getDsaRates () {
        const observable = ajax({
          url: this.BASE_URL + '/per-diem/findAll',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        }).pipe(retry(1))

        const observer = {
          next: (response) => {
            const dsaRates = response.response
            const size = dsaRates.length

            for (let i = 0; i < size; i++) {
              const dsaRate = dsaRates[i]
              const name = dsaRate.name

              // determine the [] to pyt the dsa rate
              if (name === 'Breakfast') {
                this.breakfastRates.push(dsaRate)

              } else if (name === 'Lunch') {
                this.lunchRates.push(dsaRate)

              } else if (name === 'Dinner') {
                this.dinnerRates.push(dsaRate)

              } else {
                this.incidentalRates.push(dsaRate)

              }
            }
          },
          error: () => {
          },
          complete: () => {
          }
        }
  
        observable.subscribe(observer)
      },
      // load data for the egpaf budget selected index
      loadEditEgpafBudgetModal (index) {
        // clear the existing data
        this.clearEgpafModal ()

        // assign the selected index
        this.selectedEgpafEditIndex = index
        // load the data
        const data = this.egpafParticipantsData[index]
        const breakfast = data.breakfast
        const lunch = data.lunch
        const dinner = data.dinner
        const Incidental = data.Incidental

        this.selectedEgpafEmployees = { id: data.employee.id, name: data.employee.firstname + ', ' + data.employee.surname +  ', ' + data.employee.position_name, allData: data.employee }
        
        this.egpafBreakfast = { id: data.breakfast.id, name: breakfast.amount + ', ' + breakfast.location, allData: breakfast }
        this.egpafBreakfastDays = data.breakfastDays
        
        this.egpafLunch = { id: data.lunch.id, name: lunch.amount + ', ' + lunch.location, allData: lunch }
        this.egpafLunchDays = data.lunchDays

        this.egpafDinner = { id: data.dinner.id, name: dinner.amount + ', ' + dinner.location, allData: dinner }
        this.egpafDinnerDays = data.dinnerDays

        this.egpafIncidental = { id: data.Incidental.id, name: Incidental.amount + ', ' + Incidental.location, allData: Incidental }
        this.egpafIncidentalDays = data.incidentalDays

        this.egpafNoOfStaff = data.staffNo

        this.egpafTransport = data.transport

        // open the edit modal
        this.openEditEgpafBudgetModal()
      },
      // load data for the selected index
      loadEditMoHBudgetModal (index) {
        // clear the existing data
        this.clearMoHModal ()

        // assign the selected index
        this.selectedMoHEditIndex = index
        // load the data
        const data = this.mohParticipantsData[index]
        const breakfast = data.breakfast
        const lunch = data.lunch
        const dinner = data.dinner
        const Incidental = data.Incidental

        this.selectedMoHEmployees = { id: data.employee.id, name: data.employee.firstname + ', ' + data.employee.surname +  ', ' + data.employee.position_name, allData: data.employee }

        this.mohBreakfast = { id: data.breakfast.id, name: breakfast.amount + ', ' + breakfast.location, allData: breakfast }
        this.mohBreakfastDays = data.breakfastDays

        this.mohLunch = { id: data.lunch.id, name: lunch.amount + ', ' + lunch.location, allData: lunch }
        this.mohLunchDays = data.lunchDays

        this.mohDinner = { id: data.dinner.id, name: dinner.amount + ', ' + dinner.location, allData: dinner }
        this.mohDinnerDays = data.dinnerDays

        this.mohIncidental = { id: data.Incidental.id, name: Incidental.amount + ', ' + Incidental.location, allData: Incidental }
        this.mohIncidentalDays = data.incidentalDays

        this.mohNoOfStaff = data.staffNo

        this.mohTransport = data.transport

        // open the edit modal
        this.openEditMoHBudgetModal()
      },

      // load data for the selected index
      loadUnitBudgetModal (index) {
        // clear the existing data
        this.clearUnitModal ()

        // assign the selected index
        this.selectedMoHEditIndex = index
        // load the data
        const data = this.unitBudgetData[index]

        this.unitDate = data.date
        this.unitDescription = data.description
        this.unitCost = data.cost
        this.Quantity = data.quantity

        // open the edit modal
        this.openEditUnitBudgetModal()
      },
      // load data for the selected index
      loadEditUnitBudgetModal (index) {
        // clear the existing data
        this.clearMoHModal ()

        // assign the selected index
        this.selectedUnitEditIndex = index
        // load the data
        const data = this.unitBudgetData[index]

        this.unitDate = data.date
        this.unitDescription = data.description
        this.unitCost = data.cost
        this.unitQuantity = data.quantity

        // open the edit modal
        this.openEditUnitBudgetModal()
      },

      // loads the stored budget
      loadBudget () {
        if (this.budget !== null) {
          this.showEgpafParticipants = false
          this.egpafParticipantsData = this.budget
          this.showEgpafParticipants = true
        }
      },
      // clear the budget
      clearBudget () {
        switch (this.currentParticipantsView) { 
          case 1:
            this.egpafParticipantsData = []
            break
          case 2:
            this.mohParticipantsData = []
            break
          case 3:
            this.unitBudgetData = []
            break
        }
      },
      // format the table data
      formatData (data) {
        var formattedData
        // check if it is a number
        if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
          // format the number to a currency format
          var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
          formattedData = currencyFormat.format(String(data)).substring(4)
        } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
          // check if the data is a date
          // formating the dates supplied
          var textDate = new Date(data)
          var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
          formattedData = formattedTextDate
        } else {
          formattedData = data
        }
        return formattedData
      },
      // deletes an item at an index
      deleteEgpafItem (index) {
        this.showEgpafParticipants = false
        // remove the grand total row
        const size = this.egpafParticipantsData.length
        if (size > 0) {
          const grandTotalRowIndex = size - 1
          this.egpafParticipantsData.splice(grandTotalRowIndex, 1)
        }

        this.egpafParticipantsData.splice(index, 1)
        this.showEgpafParticipants = true
        // recalculate the budget grand total
        this.calculateEgpafGrandTotal()
      },
      // deletes an item at an index
      deleteMoHItem (index) {
        this.showMoHParticipants = false
        // remove the grand total row
        const size = this.mohParticipantsData.length
        if (size > 0) {
          const grandTotalRowIndex = size - 1
          this.mohParticipantsData.splice(grandTotalRowIndex, 1)
        }

        this.mohParticipantsData.splice(index, 1)
        this.showMoHParticipants = true
        // recalculate the budget grand total
        this.calculateMoHGrandTotal()
      },
      // deletes an item at an index
      deleteUnitItem (index) {
        this.showUnitBudget = false
        // remove the grand total row
        const size = this.unitBudgetData.length
        if (size > 0) {
          const grandTotalRowIndex = size - 1
          this.unitBudgetData.splice(grandTotalRowIndex, 1)
        }

        this.unitBudgetData.splice(index, 1)
        this.showUnitBudget = true
        // recalculate the budget grand total
        this.calculateUnitGrandTotal()
      },
      // add the egpaf budget data
      addEgpafParticipantsData () {
        // check if the details are empty
        // console.log(!isNaN(this.egpafBreakfastDays), !isNaN(this.egpafLunch), !isNaN(this.egpafDinnerDays), !isNaN(this.egpafIncidentalDays) ) 
        if (this.egpafBreakfast !== '' && this.egpafLunch !== '' && this.egpafDinner !== '0' && this.egpafIncidental !== ''
            && this.selectedEgpafEmployees.length !== 0 && this.egpafTransport !== null && this.egpafTransport !== ''
            && !isNaN(this.egpafBreakfastDays) && !isNaN(this.egpafLunchDays) && !isNaN(this.egpafDinnerDays) && !isNaN(this.egpafIncidentalDays) ) {
          // remove the last grand total row if its available
          const size = this.egpafParticipantsData.length
          if (size > 0) {
            const grandTotalRowIndex = size - 1
            this.egpafParticipantsData.splice(grandTotalRowIndex, 1)
          }

          // hide the budget
          this.showEgpafParticipants = false
          // adding the data
          // console.log(this.egpafDate, this.egpafDescription, this.egpafLunch, this.egpafDinner, this.egpafIncidental, this.egpafNoOfStaff, this.selectedEgpafEmployees)
          const costTotals =  parseFloat(this.egpafTransport) +  
                              (parseFloat(this.egpafBreakfast.allData.amount) * parseFloat(this.egpafBreakfastDays) ) + 
                              (parseFloat(this.egpafLunch.allData.amount) * parseFloat(this.egpafLunchDays) ) + 
                              (parseFloat(this.egpafDinner.allData.amount) * parseFloat(this.egpafDinnerDays) ) + 
                              parseFloat(this.egpafIncidental.allData.amount * parseFloat(this.egpafIncidentalDays))
          
          // loop through the selected employees and create a row for each employee
          const employeeSize  = this.selectedEgpafEmployees.length

          for (let i = 0; i < employeeSize; i++) {
            const employee = this.selectedEgpafEmployees[i]
            const rowData = {
              employee: employee.allData,
              breakfast: this.egpafBreakfast.allData,
              breakfastDays: this.egpafBreakfastDays,
              lunch: this.egpafLunch.allData,
              lunchDays: this.egpafLunchDays,
              dinner: this.egpafDinner.allData,
              dinnerDays: this.egpafDinnerDays,
              Incidental: this.egpafIncidental.allData,
              incidentalDays: this.egpafIncidentalDays,
              transport: this.egpafTransport,
              total: costTotals
            }


            // add it in the budget if its not available in the data matrix else just update it
            var editIndex = -1

            // search for the employee
            for (let k = 0; k < this.egpafParticipantsData.length; k++) {
              const employeeId = this.egpafParticipantsData[k].employee.id

              if (employeeId === employee.id) {
                console.log('found')
                editIndex = k
                break
              }
            }

            // add it
            if (editIndex === -1) {
              this.egpafParticipantsData.push(rowData)
            } else {
              // update it
              this.egpafParticipantsData[editIndex] = rowData
            }
          }

          // // calculate the grand total
          this.calculateEgpafGrandTotal()
          // // show the budget
          this.showEgpafParticipants = true
          // //  close the add a budget modal
          this.closeAddEgpafBudgetModal()
        }
      },
      // edit egpaf budget data
      editEgpafParticipantsData () {
        // check if the details are empty
        if (this.egpafBreakfast !== '' && this.egpafLunch !== '' && this.egpafDinner !== '0' && this.egpafIncidental !== ''
            && this.selectedEgpafEmployees.length !== 0 && this.egpafTransport !== null && this.egpafTransport !== ''
            && !isNaN(this.egpafBreakfastDays) && !isNaN(this.egpafLunchDays) && !isNaN(this.egpafDinnerDays) && !isNaN(this.egpafIncidentalDays) ) {
          // remove the last grand total row if its available
          const size = this.egpafParticipantsData.length
          if (size > 0) {
            const grandTotalRowIndex = size - 1
            this.egpafParticipantsData.splice(grandTotalRowIndex, 1)
          }

          // hide the budget
          this.showEgpafParticipants = false
          

          // editing the participants details
            // adding the data
          // console.log(this.egpafDate, this.egpafDescription, this.egpafLunch, this.egpafDinner, this.egpafIncidental, this.egpafNoOfStaff, this.selectedEgpafEmployees)
          const costTotals =  parseFloat(this.egpafTransport) +  
                              (parseFloat(this.egpafBreakfast.allData.amount) * parseFloat(this.egpafBreakfastDays) ) + 
                              (parseFloat(this.egpafLunch.allData.amount) * parseFloat(this.egpafLunchDays) ) + 
                              (parseFloat(this.egpafDinner.allData.amount) * parseFloat(this.egpafDinnerDays) ) + 
                              parseFloat(this.egpafIncidental.allData.amount * parseFloat(this.egpafIncidentalDays))
          
          const rowData = {
              employee: this.selectedEgpafEmployees.allData,
              breakfast: this.egpafBreakfast.allData,
              breakfastDays: this.egpafBreakfastDays,
              lunch: this.egpafLunch.allData,
              lunchDays: this.egpafLunchDays,
              dinner: this.egpafDinner.allData,
              dinnerDays: this.egpafDinnerDays,
              Incidental: this.egpafIncidental.allData,
              incidentalDays: this.egpafIncidentalDays,
              transport: this.egpafTransport,
              total: costTotals
            }

          // add it in the budget
          this.egpafParticipantsData[this.selectedEgpafEditIndex] = rowData

          // // calculate the grand total
          this.calculateEgpafGrandTotal()
          // // show the budget
          this.showEgpafParticipants = true
          // //  close the add a budget modal
          this.closeEditEgpafParticipantModal()
        }
      },
      // calculate the grand total
      calculateEgpafGrandTotal () {
        // loop through the budget data
        const size = this.egpafParticipantsData.length
        if (size > 0) {
          var grandTotal = 0
          for (var i = 0; i < size; i++) {
            const rowTotal = parseFloat(this.egpafParticipantsData[i].total)
            grandTotal += rowTotal
          }
          const grandTotalRow = {
            employee: '',
            breakfast: '',
            breakfastDays: '',
            lunch: '',
            lunchDays: '',
            dinner: '',
            dinnerDays: '',
            Incidental: '',
            incidentalDays: '',
            transport: 'Grand Total',
            total: grandTotal
          }
          // push it into the budget data
          this.egpafParticipantsData.push(grandTotalRow)
        }
      },

      // add the moh budget data
       addMohParticipantsData () {
        // check if the details are empty
        if (this.mohBreakfast !== '' && this.mohLunch !== '' && this.mohDinner !== '0' && this.mohIncidental !== ''
            && this.selectedMoHEmployees.length !== 0 && this.mohTransport !== null && this.mohTransport !== ''
            && !isNaN(this.mohBreakfastDays) && !isNaN(this.mohLunchDays) && !isNaN(this.mohDinnerDays) && !isNaN(this.mohIncidentalDays) ) {
          // remove the last grand total row if its available
          const size = this.mohParticipantsData.length
          if (size > 0) {
            const grandTotalRowIndex = size - 1
            this.mohParticipantsData.splice(grandTotalRowIndex, 1)
          }

          // hide the budget
          this.showMoHParticipants = false
          // adding the data
          // console.log(this.egpafDate, this.egpafDescription, this.egpafLunch, this.egpafDinner, this.egpafIncidental, this.egpafNoOfStaff, this.selectedEgpafEmployees)
          const costTotals =  parseFloat(this.mohTransport) +  
                              (parseFloat(this.mohBreakfast.allData.amount) * parseFloat(this.mohBreakfastDays) ) + 
                              (parseFloat(this.mohLunch.allData.amount) * parseFloat(this.mohLunchDays) ) + 
                              (parseFloat(this.mohDinner.allData.amount) * parseFloat(this.mohDinnerDays) ) + 
                              parseFloat(this.mohIncidental.allData.amount * parseFloat(this.mohIncidentalDays))
          
          // loop through the selected employees and create a row for each employee
          const employeeSize  = this.selectedMoHEmployees.length

          for (let i = 0; i < employeeSize; i++) {
            console.log('inside')
            const employee = this.selectedMoHEmployees[i]
            const rowData = {
              employee: employee.allData,
              breakfast: this.mohBreakfast.allData,
              breakfastDays: this.mohBreakfastDays,
              lunch: this.mohLunch.allData,
              lunchDays: this.mohLunchDays,
              dinner: this.mohDinner.allData,
              dinnerDays: this.mohDinnerDays,
              Incidental: this.mohIncidental.allData,
              incidentalDays: this.mohIncidentalDays,
              transport: this.mohTransport,
              total: costTotals
            }


            // add it in the budget if its not available in the data matrix else just update it
            var editIndex = -1

            // search for the employee
            for (let k = 0; k < this.mohParticipantsData.length; k++) {
              const employeeId = this.mohParticipantsData[k].employee.id

              if (employeeId === employee.id) {
                editIndex = k
                break
              }
            }

            // add it
            if (editIndex === -1) {
              this.mohParticipantsData.push(rowData)
            } else {
              // update it
              this.mohParticipantsData[editIndex] = rowData
            }
          }

          // // calculate the grand total
          this.calculateMoHGrandTotal()
          // // show the budget
          this.showMoHParticipants = true
          // //  close the add a budget modal
          this.closeAddMoHBudgetModal()
        } else {
          console.log(this.mohBreakfast !== '', this.mohLunch !== '', this.mohDinner !== '0', this.mohIncidental !== ''
            , this.selectedMoHEmployees.length !== 0, this.mohTransport !== null, this.mohTransport !== ''
            , !isNaN(this.mohBreakfastDays), !isNaN(this.mohLunchDays), !isNaN(this.mohDinnerDays), !isNaN(this.mohIncidentalDays) )
        }
      },
      // edit egpaf budget data
      editmohParticipantsData () {
        // check if the details are empty
        if (this.mohBreakfast !== '' && this.mohLunch !== '' && this.mohDinner !== '0' && this.mohIncidental !== ''
            && this.selectedMoHEmployees.length !== 0 && this.mohTransport !== null && this.mohTransport !== ''
            && !isNaN(this.mohBreakfastDays) && !isNaN(this.mohLunchDays) && !isNaN(this.mohDinnerDays) && !isNaN(this.mohIncidentalDays) ) {
          // remove the last grand total row if its available
          const size = this.mohParticipantsData.length
          if (size > 0) {
            const grandTotalRowIndex = size - 1
            this.mohParticipantsData.splice(grandTotalRowIndex, 1)
          }

          // hide the budget
          this.showMoHParticipants = false
          

          // editing the participants details
          // adding the data
          const costTotals =  parseFloat(this.mohTransport) +  
                              (parseFloat(this.mohBreakfast.allData.amount) * parseFloat(this.mohBreakfastDays) ) + 
                              (parseFloat(this.mohLunch.allData.amount) * parseFloat(this.mohLunchDays) ) + 
                              (parseFloat(this.mohDinner.allData.amount) * parseFloat(this.mohDinnerDays) ) + 
                              parseFloat(this.mohIncidental.allData.amount * parseFloat(this.mohIncidentalDays))
          
          const rowData = {
              employee: this.selectedMoHEmployees.allData,
              breakfast: this.mohBreakfast.allData,
              breakfastDays: this.mohBreakfastDays,
              lunch: this.mohLunch.allData,
              lunchDays: this.mohLunchDays,
              dinner: this.mohDinner.allData,
              dinnerDays: this.mohDinnerDays,
              Incidental: this.mohIncidental.allData,
              incidentalDays: this.mohIncidentalDays,
              transport: this.mohTransport,
              total: costTotals
            }

          // add it in the budget
          this.mohParticipantsData[this.selectedMoHEditIndex] = rowData

          // // calculate the grand total
          this.calculateMoHGrandTotal()
          // // show the budget
          this.showMoHParticipants = true
          // //  close the add a budget modal
          this.closeEditMoHParticipantModal()
        }
      },
      // calculate the grand total
      calculateMoHGrandTotal () {
        // loop through the budget data
        const size = this.mohParticipantsData.length
        if (size > 0) {
          var grandTotal = 0
          for (var i = 0; i < size; i++) {
            const rowTotal = parseFloat(this.mohParticipantsData[i].total)
            grandTotal += rowTotal
          }
          const grandTotalRow = {
            employee: '',
            breakfast: '',
            breakfastDays: '',
            lunch: '',
            lunchDays: '',
            dinner: '',
            dinnerDays: '',
            Incidental: '',
            incidentalDays: '',
            transport: 'Grand Total',
            total: grandTotal
          }
          // push it into the budget data
          this.mohParticipantsData.push(grandTotalRow)
        }
      },


      // add the unit cost budget data
      addUnitBudgetData () {
        // check if the details are empty
        if (this.unitDate !== '' && this.unitDescription !== '' && this.unitCost !== '' && this.unitQuantity !== '') {
          // remove the last grand total row if its available
          const size = this.unitBudgetData.length
          if (size > 0) {
            const grandTotalRowIndex = size - 1
            this.unitBudgetData.splice(grandTotalRowIndex, 1)
          }

          // hide the budget
          this.showUnitBudget = false
          // adding the data
          // console.log(this.egpafDate, this.egpafDescription, this.egpafLunch, this.egpafDinner, this.egpafIncidental, this.egpafNoOfStaff, this.selectedEgpafEmployees)
          const total = parseFloat(this.unitCost) * this.unitQuantity
          const rowData = {
            date: this.unitDate,
            description: this.unitDescription,
            cost: this.unitCost,
            quantity: this.unitQuantity,
            total: total
          }

          // add it in the budget
          this.unitBudgetData.push(rowData)
          // // calculate the grand total
          this.calculateUnitGrandTotal()
          // // show the budget
          this.showUnitBudget = true
          // //  close the add a budget modal
          this.closeAddParticipantModal()
        }
      },
      // edit egpaf budget data
      editUnitBudgetData () {
        // check if the details are empty
        if (this.unitDate !== '' && this.unitDescription !== '' && this.unitCost !== '' && this.unitQuantity !== '')  {
          // remove the last grand total row if its available
          const size = this.unitBudgetData.length
          if (size > 0) {
            const grandTotalRowIndex = size - 1
            this.unitBudgetData.splice(grandTotalRowIndex, 1)
          }

          // hide the budget
          this.showUnitBudget = false

          // adding the data
          const total = parseFloat(this.unitCost) * this.unitQuantity
          const rowData = {
            date: this.unitDate,
            description: this.unitDescription,
            cost: this.unitCost,
            quantity: this.unitQuantity,
            total: total
          }

          // add it in the budget
          this.unitBudgetData[this.selectedUnitEditIndex] = rowData

          // // calculate the grand total
          this.calculateUnitGrandTotal()
          // // show the budget
          this.showUnitBudget = true
          // //  close the add a budget modal
          this.closeEditUnitBudgetModal()
        }
      },
      // calculate the grand total
      calculateUnitGrandTotal () {
        // loop through the budget data
        const size = this.unitBudgetData.length
        if (size > 0) {
          var grandTotal = 0
          for (var i = 0; i < size; i++) {
            const rowTotal = parseFloat(this.unitBudgetData[i].total)
            grandTotal += rowTotal
          }
          const grandTotalRow = {
            date: '',
            description: '',
            cost: '',
            quantity: 'Grand Total',
            total: grandTotal
          }
          // push it into the budget data
          this.unitBudgetData.push(grandTotalRow)
        }
      },
      back () {
        // moving to the next step
        this.$router.push('create-budget')
      },
      next () {
        // if (this.egpafParticipantsData.length !== 0) {
        //   // moving to the next step
        //   this.$router.push('activitySummary')
        //   // store the data
        //   this.$store.commit('mutateBudget', this.egpafParticipantsData)
        // } else {
        //   this.isOk = false
        //   this.messageTitle = 'Error Message'
        //   this.message = 'Fill at least one row in the budget to move to the next step...'
        //   this.$refs.messageModal.openMessageModal()
        // }
        this.$router.push('create-schedule')
      },
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      }
    }
  }
  </script>
  
  <style>
  
  #chart-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 40vh;
  }
  
  </style>
``  