<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
          <span><span class=" text-emerald-500">>Home</span> > Activity Type Management</span>
          <span>{{ firstname }} {{ surname }}</span>
        </div>
          <div>
          <!-- Timeline -->
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
              <!-- <svg  class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m11.349,24H0V3C0,1.346,1.346,0,3,0h12c1.654,0,3,1.346,3,3v5.059c-.329-.036-.662-.059-1-.059s-.671.022-1,.059V3c0-.552-.448-1-1-1H3c-.552,0-1,.448-1,1v19h7.518c.506.756,1.125,1.429,1.831,2Zm0-14h-7.349v2h5.518c.506-.756,1.125-1.429,1.831-2Zm-7.349,7h4c0-.688.084-1.356.231-2h-4.231v2Zm20,0c0,3.859-3.141,7-7,7s-7-3.141-7-7,3.141-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5ZM14,5H4v2h10v-2Zm5.589,9.692l-3.228,3.175-1.63-1.58-1.393,1.436,1.845,1.788c.314.315.733.489,1.179.489s.865-.174,1.173-.482l3.456-3.399-1.402-1.426Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,10.039c-3.859,0-7,3.14-7,7,0,3.838,3.141,6.961,7,6.961s7-3.14,7-7c0-3.838-3.141-6.961-7-6.961Zm0,11.961c-2.757,0-5-2.226-5-4.961,0-2.757,2.243-5,5-5s5,2.226,5,4.961c0,2.757-2.243,5-5,5Zm1.707-4.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1-1c-.188-.188-.293-.442-.293-.707v-2c0-.552,.447-1,1-1s1,.448,1,1v1.586l.707,.707Zm5.293-10.293v2c0,.552-.447,1-1,1s-1-.448-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1H11c.552,0,1,.448,1,1s-.448,1-1,1H2v9c0,1.654,1.346,3,3,3h4c.552,0,1,.448,1,1s-.448,1-1,1H5c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h1V1c0-.552,.448-1,1-1s1,.448,1,1v1h8V1c0-.552,.447-1,1-1s1,.448,1,1v1h1c2.757,0,5,2.243,5,5Z"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600">Activity Type Management</p>
            </div>
            <!-- {{ activityTypeData }} -->
              <!-- The pending activity approvals -->
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-8 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 sm:gap-y-4">
                <div class="flex justify-between text-center">
                  <span></span>
                  <button @click="openAddModal()" class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Add an Activity Type</button>
                </div>

                <div class=" flex">
                  <input
                    v-model="filter"
                    type="text"
                    placeholder="Search..."
                    required class="shadow-sm bg-gray-50 text-gray-600 w-full text-size border px-2 py-3 border-gray-300  focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 "/>
                </div>

                <div class=" overflow-x-auto">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">#</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Name</td>
                        <td class="py-3 px-2 border text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in filteredRows" :key="i" class="text-gray-500 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ (i + 1) }}</td>
                        <td class="py-3 px-2 border text-left">{{data.name}}</td>
                        <td class="py-3 px-2 border mx-auto flex text-center">
                          <div class="flex mx-auto space-x-4">
                            <svg @click="deleteItem(data.id)" class="h-5 w-5 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <!-- <svg @click="loadEditBudgetModal(i)" class="h-5 w-5 text-emerald-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m21,0H3C1.346,0,0,1.346,0,3v21h24V3c0-1.654-1.346-3-3-3Zm-2.879,10.621l-7.878,7.879h-4.243v-4.242l7.879-7.879c1.133-1.133,3.109-1.133,4.243,0,1.169,1.17,1.169,3.072,0,4.242Zm-3.871,1.043l-4.836,4.836h-1.415v-1.414l4.836-4.836,1.414,1.414Zm2.457-3.871c.39.39.39,1.024,0,1.414l-1.043,1.043-1.414-1.414,1.043-1.043c.377-.379,1.036-.379,1.414,0Z"/></svg> -->
                            <svg @click="editItem(data)" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>


              </div>
              <div class="flex mb-5">
                <div class=" flex mx-auto items-center">
                  <div v-if="startRow !== 0" @click="movePages(-1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer hover:bg-emerald-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div v-if="(startRow+rowsPerPage) < activityTypeData.length" @click="movePages(1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer border-l hover:bg-emerald-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add funding modal -->
    <TransitionRoot appear :show="isAddOpen" @close="closeAddModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-4xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Add an Activity Type
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-1 grid-rows-1 gap-4 py-4">
                    <!-- Description -->
                    <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Activity Type <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="name"
                        placeholder="Activity Type"
                        requiered
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeAddModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="addData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- edit funding modal -->
    <TransitionRoot appear :show="isEditOpen" @close="closeEditModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-4xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Edit an Activity Type
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-1 grid-rows-1 gap-4 py-4">
                    <!-- Description -->
                    <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Activity Type <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="name"
                        placeholder="Activity Type"
                        required
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeEditModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="editData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Edit
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
  </TransitionRoot>
    <!-- message feedback modal -->
    <MessageModal
      :isOk="isOk"
      :title="messageTitle"
      :message="message"
      ref="messageModal"
    />
    <!-- Loader modal -->
    <LoadingModal
      ref="loadingModal"
    />
  </div>
</template>

<script>
import Navbar from '@/components/AdminNavbar.vue'
import Sidebar from '@/components/AdminSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { retry } from 'rxjs'
import { ref } from 'vue'
import MessageModal from './MessageModal.vue'
import LoadingModal from './LoadingModal.vue'
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'

export default {
  computed: {
    ...mapState([
      'id',
      'firstname',
      'surname',
      'token'
    ]),
    filteredRows () {
      return this.activityTypeData.filter((row) => {
        const searchText = this.filter.toLowerCase()

        return JSON.stringify(row).toLowerCase().includes(searchText)
      }).slice(this.startRow, this.startRow + this.rowsPerPage)
    }
  },
  components: {
    Navbar,
    Sidebar,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    MessageModal,
    LoadingModal
  },
  setup () {
    const isAddOpen = ref(false)
    const isEditOpen = ref(false)
    return {
      isAddOpen,
      isEditOpen,
      closeAddModal () {
        isAddOpen.value = false
      },
      openAddModal () {
        isAddOpen.value = true
      },
      closeEditModal () {
        isEditOpen.value = false
      },
      openEditModal () {
        isEditOpen.value = true
      }
    }
  },
  name: 'Office-management',
  props: {
  },
  data: () => {
    return {
      name: '',
      filter: '',
      startRow: 0,
      rowsPerPage: 10,
      endPageItemIndex: 10,
      showRequests: false,
      expandBody: false,
      activityTypeData: [],
      message: '',
      messageTitle: '',
      idOk: false,
      editId: null,
      BASE_URL: process.env.VUE_APP_BASE_URL,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    this.$store.commit('mutateActiveListItemIndex', 8)
    this.$store.commit('mutateSelectedSubMenuItem', 20)
    // getting the pending activity approvals
    this.getActivityTypes()
  },
  methods: {
     // add an item
     addData () {
      // close the modal
      this.closeAddModal()

      if (this.name.trim() !== '' &&  this.name.length > 1) {
        this.$refs.loadingModal.openLoaderModal()

        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/activity-type/insert',
          method: 'POST',
          body: {
            name: this.name
          },
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })

        const observer = {
          next: () => {
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = true
            this.messageTitle = 'Message'
            this.message = 'Successfully submitted the activity type...'
            this.$refs.messageModal.openMessageModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to submit the activity type, please try again later...'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.getActivityTypes()
            this.$refs.loadingModal.closeLoaderModal()
          }
        }

        observable.subscribe(observer)

      } else {
        // showing the dialog for the error
        this.isOk = false
        this.messageTitle = 'Error Message'
        this.message = 'Cannot submit an empty or invalid activity type.'
        this.$refs.messageModal.openMessageModal()
      }
    },
    // edit an item
    editData () {
      // close the modal
      this.closeEditModal()

      if (this.name.trim() !== '' &&  this.name.length > 1) {
        this.$refs.loadingModal.openLoaderModal()

        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/activity-type/update',
          method: 'PUT',
          body: {
            id: this.editId,
            name: this.name
          },
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })

        const observer = {
          next: () => {
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = true
            this.messageTitle = 'Message'
            this.message = 'Successfully edited the activity type...'
            this.$refs.messageModal.openMessageModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to edit the activity type, please try again later...'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
            this.getActivityTypes()
          }
        }

        observable.subscribe(observer)

      } else {
        // showing the dialog for the error
        this.isOk = false
        this.messageTitle = 'Error Message'
        this.message = 'Cannot edit an empty or invalid activity type.'
        this.$refs.messageModal.openMessageModal()
      }
    },
    // edit item
    editItem (data) {
      const id = data.id
      const source = data.name

      this.editId = id
      this.name = source

      this.openEditModal()
    },
    // deletes the item
    deleteItem (id) {
      const observable = ajax({
      url: process.env.VUE_APP_BASE_URL + '/activity-type/delete?id='+id,
      method: 'DELETE',
      headers: {
          Authorization: `Bearer ${this.token}`
        }
      })

      const observer = {
        next: () => {
          this.$refs.loadingModal.closeLoaderModal()
          // showing the dialog for the error
          this.isOk = true
          this.messageTitle = 'Message'
          this.message = 'Successfully deleted the activity type...'
          this.$refs.messageModal.openMessageModal()
        },
        error: () => {
          // close the loader modal
          this.$refs.loadingModal.closeLoaderModal()
          // showing the dialog for the error
          this.isOk = false
          this.messageTitle = 'Error Message'
          this.message = 'Failed to delete the activity type, please try again later...'
          this.$refs.messageModal.openMessageModal()
        },
        complete: () => {
          this.$refs.loadingModal.closeLoaderModal()
          this.getActivityTypes()
        }
      }

      observable.subscribe(observer)
    },
    // moves the pages of the pagenation
    movePages (amount) {
      var newStartRow = this.startRow + (amount * this.rowsPerPage)
      // only move if its greater than 0
      if (newStartRow >= 0 && newStartRow < this.activityTypeData.length) {
        this.startRow = newStartRow
      }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // getting the pending activity approvals
    getActivityTypes () {
      this.showRequests = false
      const observable = ajax({
        url: this.BASE_URL + '/activity-type/findAll',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          const data = response.response
          this.activityTypeData = data
          this.showRequests = true
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
