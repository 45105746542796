<template>
    <div id="sidebar-x" class="row-span-full fixed h-screen transform bg-white shadow-2xl border-r opacity-100 z-10" style="width: 19rem;">
          <!-- the side nav -->
          <nav class="text-left mx-5 mt-4 flex flex-col space-y-4">
            <!-- Home list item -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==0 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(0)">
              <a href="">
                <div class="flex space-x-2 items-center">
                  <svg :class="[activeListItemIndex==0 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19,15h-1c-.553,0-1-.447-1-1s.447-1,1-1h1c.553,0,1,.447,1,1s-.447,1-1,1Zm1,3c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm-4-12c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm4,0c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm0,4c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm4,9V5c0-2.757-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5,0,.553,.447,1,1,1s1-.447,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3v14c0,1.654-1.346,3-3,3h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c2.757,0,5-2.243,5-5Zm-8,.5v-4.152c0-1.548-.699-2.982-1.919-3.938l-3-2.349c-1.814-1.418-4.348-1.419-6.162,0l-3,2.348c-1.22,.955-1.919,2.39-1.919,3.938v4.152c0,2.481,2.019,4.5,4.5,4.5h7c2.481,0,4.5-2.019,4.5-4.5Zm-6.151-8.863l3,2.348c.731,.573,1.151,1.435,1.151,2.363v4.152c0,1.379-1.121,2.5-2.5,2.5H4.5c-1.379,0-2.5-1.121-2.5-2.5v-4.152c0-.929,.42-1.79,1.151-2.363l3-2.347c.544-.426,1.196-.639,1.849-.639s1.305,.213,1.849,.638Zm.151,7.363v-2c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1Z"/></svg>
                  <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==0 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Home</span>
                </div>
              </a>
            </div>
            <!-- Activity Creation -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==1 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(1)">
              <a href="">
                <div class="flex space-x-2 items-center">
                  <!-- <svg :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m16 16a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1zm6-5.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z"/></svg> -->
                  <!-- <svg :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m14.414 0h-9.414a3 3 0 0 0 -3 3v21h20v-16.414zm.586 3.414 3.586 3.586h-3.586zm-11 18.586v-19a1 1 0 0 1 1-1h8v7h7v13zm9-8h3v2h-3v3h-2v-3h-3v-2h3v-3h2z"/></svg> -->
                  <!-- <svg :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m19,0H5C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h11.646c1.287,0,2.497-.501,3.407-1.411l2.536-2.536c.897-.896,1.411-2.139,1.411-3.407V5c0-2.757-2.243-5-5-5ZM2,19V5c0-1.654,1.346-3,3-3h14c1.654,0,3,1.346,3,3v10h-4c-1.654,0-3,1.346-3,3v4H5c-1.654,0-3-1.346-3-3Zm16.639,2.175c-.448.448-1.02.725-1.639.802v-3.977c0-.552.449-1,1-1h3.976c-.079.615-.361,1.198-.802,1.639l-2.536,2.536ZM5,6.5c0-.828.672-1.5,1.5-1.5s1.5.672,1.5,1.5-.672,1.5-1.5,1.5-1.5-.672-1.5-1.5Zm3,5.5c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm0,5.5c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z"/></svg> -->
                  <svg :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.89,9.65c-.36-.42-.32-1.05,.1-1.41,.42-.36,1.05-.32,1.41,.1,1.07,1.24,1.85,2.71,2.32,4.38,.15,.53-.16,1.08-.69,1.23-.09,.03-.18,.04-.27,.04-.44,0-.84-.29-.96-.73-.39-1.39-1.04-2.6-1.91-3.62Zm4.06,7.66c-1.36,4.08-4.31,4.68-5.95,4.68-1.41,0-2.64-.62-3.66-1.55-1.18,.93-2.63,1.55-4.34,1.55s-3.22-.95-4.63-2.4c-1.25,1.2-3.33,2.4-4.37,2.4-.46,0-.88-.33-.98-.8-.11-.54,.24-1.07,.78-1.18,1.15-.24,2.26-.96,3.27-1.96C1.64,14.78,0,10.24,0,7.5,0,4.57,2.54,2,5.43,2s5.57,2.62,5.57,5.5c0,2.69-1.65,7.29-4.29,10.61,1.08,1.15,2.22,1.89,3.29,1.89,1.17,0,2.19-.42,3.02-1.06-1.31-1.86-2.02-4.12-2.02-5.48,0-1.84,1.64-3.45,3.51-3.45s3.49,1.61,3.49,3.45c0,1.51-.77,3.77-2.23,5.58,.67,.59,1.42,.97,2.23,.97,1.95,0,3.32-1.12,4.05-3.32,.17-.52,.74-.81,1.27-.63,.52,.17,.81,.74,.63,1.27ZM9,7.5c0-1.8-1.74-3.5-3.57-3.5s-3.43,1.67-3.43,3.5c0,2.39,1.47,6.2,3.41,8.99,2.14-2.88,3.59-6.79,3.59-8.99Zm5.45,9.96c1.02-1.4,1.55-3.05,1.55-4,0-.73-.74-1.45-1.49-1.45s-1.51,.73-1.51,1.45c0,.95,.55,2.6,1.45,4Z"/></svg>
                  <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==1 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Create a Request</span>
                </div>
              </a>
            </div>
            <!-- Aprove Requests -->
            <div v-if="isAnApprover" v-bind="activeListItemIndex" :class="[activeListItemIndex==2 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(2)">
              <a href="">
                <div class="flex items-center">
                  <!-- <svg :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m16 16a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1zm6-5.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z"/></svg> -->
                  <!-- <svg :class="[activeListItemIndex==2 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m11.349,24H0V3C0,1.346,1.346,0,3,0h12c1.654,0,3,1.346,3,3v5.059c-.329-.036-.662-.059-1-.059s-.671.022-1,.059V3c0-.552-.448-1-1-1H3c-.552,0-1,.448-1,1v19h7.518c.506.756,1.125,1.429,1.831,2Zm0-14h-7.349v2h5.518c.506-.756,1.125-1.429,1.831-2Zm-7.349,7h4c0-.688.084-1.356.231-2h-4.231v2Zm20,0c0,3.859-3.141,7-7,7s-7-3.141-7-7,3.141-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5ZM14,5H4v2h10v-2Zm5.589,9.692l-3.228,3.175-1.63-1.58-1.393,1.436,1.845,1.788c.314.315.733.489,1.179.489s.865-.174,1.173-.482l3.456-3.399-1.402-1.426Z"/></svg> -->
                  <div class="flex space-x-2">
                    <svg :class="[activeListItemIndex==2 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==2 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Approve Requests</span>
                  </div>
                  <svg v-if="hasPendingRequests" class="h-4 w-4 fill-red-400 ml-12" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M20.93,7.3c-.34-1.91-2-3.3-3.94-3.3h-.16c.11-.31,.17-.65,.17-1,0-1.65-1.35-3-3-3h-4c-1.65,0-3,1.35-3,3,0,.35,.06,.69,.17,1h-.16c-1.94,0-3.6,1.39-3.94,3.3L.81,20h7.19c0,2.21,1.79,4,4,4s4-1.79,4-4h7.19l-2.27-12.7ZM9,3c0-.55,.45-1,1-1h4c.55,0,1,.45,1,1s-.45,1-1,1h-4c-.55,0-1-.45-1-1Zm3,19c-1.1,0-2-.9-2-2h4c0,1.1-.9,2-2,2ZM3.19,18l1.85-10.35c.17-.96,1-1.65,1.97-1.65h9.98c.97,0,1.8,.69,1.97,1.65l1.85,10.35H3.19Z"/></svg>
                </div>
              </a>
            </div>
            <!-- Activities Timeline -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==3 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(3)">
              <a href="">
                <div class="flex space-x-2 items-center">
                  <!-- <svg :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m16 16a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1zm6-5.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z"/></svg> -->
                  <!-- <svg :class="[activeListItemIndex==2 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m11.349,24H0V3C0,1.346,1.346,0,3,0h12c1.654,0,3,1.346,3,3v5.059c-.329-.036-.662-.059-1-.059s-.671.022-1,.059V3c0-.552-.448-1-1-1H3c-.552,0-1,.448-1,1v19h7.518c.506.756,1.125,1.429,1.831,2Zm0-14h-7.349v2h5.518c.506-.756,1.125-1.429,1.831-2Zm-7.349,7h4c0-.688.084-1.356.231-2h-4.231v2Zm20,0c0,3.859-3.141,7-7,7s-7-3.141-7-7,3.141-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5ZM14,5H4v2h10v-2Zm5.589,9.692l-3.228,3.175-1.63-1.58-1.393,1.436,1.845,1.788c.314.315.733.489,1.179.489s.865-.174,1.173-.482l3.456-3.399-1.402-1.426Z"/></svg> -->
                  <!-- <svg :class="[activeListItemIndex==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
                  <!-- <svg :class="[activeListItemIndex==3 ? 'h-5 w-5 text-emerald-500 fill-current' : 'h-5 w-5 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,20c0,2.206-1.794,4-4,4H7.854l2.045-2h10.102c1.103,0,2-.897,2-2s-.897-2-2-2h-5c-2.206,0-4-1.794-4-4,0-1.927,1.37-3.539,3.187-3.916l1.959,1.916h-1.146c-1.103,0-2,.897-2,2s.897,2,2,2h5c2.206,0,4,1.794,4,4Zm-1.465-11.464c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.949,1.95-1.949,5.122,.008,7.079l3.528,3.45,3.535-3.458Zm-1.414-5.657c1.17,1.17,1.17,3.073,.008,4.235l-2.129,2.082-2.122-2.074c-1.169-1.17-1.169-3.073,0-4.243,.567-.567,1.32-.879,2.122-.879s1.555,.312,2.121,.878ZM8.535,20.536c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.949,1.95-1.949,5.122,.008,7.079l3.528,3.45,3.535-3.458Zm-1.414-5.657c1.17,1.17,1.17,3.073,.008,4.235l-2.129,2.082-2.122-2.074c-1.169-1.17-1.169-3.073,0-4.243,.567-.567,1.32-.879,2.122-.879s1.555,.312,2.121,.878Z"/></svg> -->
                  <svg :class="[activeListItemIndex==3 ? 'h-5 w-5 text-emerald-500 fill-current' : 'h-5 w-5 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                    <path d="m17.247,10.279c.483.473,1.118.709,1.753.709s1.27-.236,1.752-.709l1.783-1.744c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591.52-3.536,1.464c-1.949,1.95-1.949,5.122.008,7.079l1.775,1.736Zm-.369-7.4c.567-.567,1.32-.879,2.122-.879s1.555.312,2.121.878c1.17,1.17,1.17,3.073.008,4.235l-1.775,1.736c-.195.192-.513.192-.708,0l-1.767-1.728c-1.169-1.17-1.169-3.073,0-4.243ZM4,22c0,1.105-.895,2-2,2s-2-.895-2-2,.895-2,2-2,2,.895,2,2Zm10.5-6c0,1.105-.895,2-2,2s-2-.895-2-2,.895-2,2-2,2,.895,2,2Zm-12.5-3c0-2.206,1.794-4,4-4h7c.552,0,1,.448,1,1s-.448,1-1,1h-7c-1.103,0-2,.897-2,2s.897,2,2,2h2c.552,0,1,.448,1,1s-.448,1-1,1h-2c-2.206,0-4-1.794-4-4Zm22,6c0,2.206-1.794,4-4,4H7c-.552,0-1-.448-1-1s.448-1,1-1h13c1.103,0,2-.897,2-2s-.897-2-2-2h-3c-.552,0-1-.448-1-1s.448-1,1-1h3c2.206,0,4,1.794,4,4Zm-3.999-14.213c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z"/>
                  </svg>
                  <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==3 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Request Timeline</span>
                </div>
              </a>
            </div>
            <!-- Manage My Transactions -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==4 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color: #42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14,13h2v2h-2v-2Zm4,2h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM14,7h2v-2h-2v2Zm4,0h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM24,3V24H0V8.829c0-.801,.312-1.555,.879-2.122l2.707-2.707c1.289-1.29,3.539-1.29,4.828,0l1.586,1.586V3c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3Zm-14,5.829c0-.267-.104-.519-.293-.708l-2.707-2.707c-.526-.526-1.474-.526-2,0l-2.707,2.707c-.189,.189-.293,.44-.293,.708v13.171H10V8.829ZM22,3c0-.551-.449-1-1-1H13c-.551,0-1,.449-1,1V22h10V3ZM5,15h2v-2h-2v2Zm0-4h2v-2h-2v2Zm0,8h2v-2h-2v2Z"/></svg> -->
                    <svg :class="[activeListItemIndex==4 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m19,0H5c-1.654,0-3,1.346-3,3v21h20V3c0-1.654-1.346-3-3-3ZM5,2h14c.551,0,1,.448,1,1v2H4v-2c0-.552.449-1,1-1Zm-1,20V7h16v15H4Zm3-12h10v2H7v-2Zm0,4h6v2h-6v-2Z"/></svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==4 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Transactions History</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==4" class="w-3 h-3 text-emerald-500 fill-current mx-10" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-10" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-2 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">My Transaction History</span>
                      </a>
                    </div>
                    <div  v-if="isAnApprover" v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==1 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(4)">
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 fill-current" id="Outline" viewBox="0 0 24 24" width="512" height="512"><circle cx="7" cy="22" r="2"/><circle cx="17" cy="22" r="2"/><path d="M23,3H21V1a1,1,0,0,0-2,0V3H17a1,1,0,0,0,0,2h2V7a1,1,0,0,0,2,0V5h2a1,1,0,0,0,0-2Z"/><path d="M21.771,9.726a.994.994,0,0,0-1.162.806A3,3,0,0,1,17.657,13H5.418l-.94-8H13a1,1,0,0,0,0-2H4.242L4.2,2.648A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.829-2H17.657a5,5,0,0,0,4.921-4.112A1,1,0,0,0,21.771,9.726Z"/></svg> -->
                      <!-- <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A11.972,11.972,0,0,0,4,3.073V1A1,1,0,0,0,2,1V4A3,3,0,0,0,5,7H8A1,1,0,0,0,8,5H5a.854.854,0,0,1-.1-.021A9.987,9.987,0,1,1,2,12a1,1,0,0,0-2,0A12,12,0,1,0,12,0Z"/><path d="M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==1 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m15.5 13.5h-2.19a1.991 1.991 0 0 1 -2.81-2.81v-3.69a1.5 1.5 0 0 1 3 0v3.5h2a1.5 1.5 0 0 1 0 3zm-3.5-13.5a1.5 1.5 0 0 0 0 3 9.01 9.01 0 0 1 9 9 1.5 1.5 0 0 0 3 0 12.013 12.013 0 0 0 -12-12zm-9 12.5a1.5 1.5 0 1 0 -1.5 1.5 1.5 1.5 0 0 0 1.5-1.5zm3.74-10.606a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm-3.827 3.856a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm3.827 14.356a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm-3.827-3.856a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm9.087 4.75a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm9.106-5.24a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm-3.856 3.827a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==1 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m6,1c0-.553.448-1,1-1h10c.552,0,1,.447,1,1s-.448,1-1,1H7c-.552,0-1-.447-1-1Zm-2,6h16c.552,0,1-.447,1-1s-.448-1-1-1H4c-.552,0-1,.447-1,1s.448,1,1,1Zm15.5,9h-1.5v-1.5c0-.553-.448-1-1-1s-1,.447-1,1v1.5h-1.5c-.552,0-1,.447-1,1s.448,1,1,1h1.5v1.5c0,.553.448,1,1,1s1-.447,1-1v-1.5h1.5c.552,0,1-.447,1-1s-.448-1-1-1Zm4.5,1c0,3.859-3.14,7-7,7s-7-3.141-7-7,3.14-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5Zm-13,5h-4c-1.654,0-3-1.346-3-3v-4c0-1.654,1.346-3,3-3h4c.552,0,1-.447,1-1s-.448-1-1-1h-4c-2.757,0-5,2.243-5,5v4c0,2.757,2.243,5,5,5h4c.552,0,1-.447,1-1s-.448-1-1-1Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Request Approval History</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==2 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(5)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,10.039c-3.859,0-7,3.14-7,7,0,3.838,3.141,6.961,7,6.961s7-3.14,7-7c0-3.838-3.141-6.961-7-6.961Zm0,11.961c-2.757,0-5-2.226-5-4.961,0-2.757,2.243-5,5-5s5,2.226,5,4.961c0,2.757-2.243,5-5,5Zm1.707-4.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1-1c-.188-.188-.293-.442-.293-.707v-2c0-.552,.447-1,1-1s1,.448,1,1v1.586l.707,.707Zm5.293-10.293v2c0,.552-.447,1-1,1s-1-.448-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1H11c.552,0,1,.448,1,1s-.448,1-1,1H2v9c0,1.654,1.346,3,3,3h4c.552,0,1,.448,1,1s-.448,1-1,1H5c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h1V1c0-.552,.448-1,1-1s1,.448,1,1v1h8V1c0-.552,.447-1,1-1s1,.448,1,1v1h1c2.757,0,5,2.243,5,5Z"/></svg>
                      <span class="text-size-sm text-gray-700">My Request History</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==5 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(10)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==5 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m14,17h6v2h-6v-2Zm0-4v2h2v-2h-2Zm6-9h-12v3.159c.716.113,1.387.284,2,.511v-1.67h8v2h-7.233c1.045.508,1.878,1.189,2.437,2h6.796v-6Zm1-4H7c-1.657,0-3,1.343-3,3v4.159c.634-.1,1.3-.159,2-.159V3c0-.552.448-1,1-1h14c.552,0,1,.448,1,1v19h-8.275c-.278.752-.76,1.428-1.417,2h11.692V3c0-1.657-1.343-3-3-3Zm-1,13h-2v2h2v-2Zm-8-.5v8c0,1.995-2.579,3.5-6,3.5s-6-1.505-6-3.5v-8c0-1.995,2.579-3.5,6-3.5s6,1.505,6,3.5Zm-2,8v-1.348c-1.046.533-2.435.848-4,.848s-2.954-.315-4-.848v1.348c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5Zm0-4v-1.348c-1.046.533-2.435.848-4,.848s-2.954-.315-4-.848v1.348c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5Zm0-4c0-.529-1.519-1.5-4-1.5s-4,.971-4,1.5,1.519,1.5,4,1.5,4-.971,4-1.5Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">My Liquidation History</span>
                    </div>
                    <div  v-if="isAnApprover" v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==6 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(11)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==6 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,8.01c-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4-1.79-4-4-4Zm0,6c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM20,0H4C1.79,0,0,1.8,0,4.01v1C0,7.21,1.79,9.01,4,9.01v10c0,2.76,2.24,5,5,5h6c2.76,0,5-2.24,5-5V9.01c2.21,0,4-1.79,4-4v-1C24,1.8,22.21,0,20,0Zm2,5c0,1.1-.9,2-2,2v-2c0-.55-.45-1-1-1s-1,.45-1,1v14c0,1.65-1.35,3-3,3h-6c-1.65,0-3-1.35-3-3V5.01c0-.55-.45-1-1-1s-1,.45-1,1v2c-1.1,0-2-.9-2-2v-1c0-1.1,.9-2,2-2H20c1.1,0,2,.9,2,2v1Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==6 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M21,0H3C1.35,0,0,1.35,0,3v6H4v15H20V9h4V3c0-1.65-1.35-3-3-3Zm-1,7v-3h-2V22H6V4h-2v3H2V3c0-.55,.45-1,1-1H21c.55,0,1,.45,1,1V7h-2Zm-8,1c-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4-1.79-4-4-4Zm0,6c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Z"/></svg>
                      <span class="text-size-sm text-gray-700">Liquidation Approval History</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Manage My Account -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==5 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14,13h2v2h-2v-2Zm4,2h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM14,7h2v-2h-2v2Zm4,0h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM24,3V24H0V8.829c0-.801,.312-1.555,.879-2.122l2.707-2.707c1.289-1.29,3.539-1.29,4.828,0l1.586,1.586V3c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3Zm-14,5.829c0-.267-.104-.519-.293-.708l-2.707-2.707c-.526-.526-1.474-.526-2,0l-2.707,2.707c-.189,.189-.293,.44-.293,.708v13.171H10V8.829ZM22,3c0-.551-.449-1-1-1H13c-.551,0-1,.449-1,1V22h10V3ZM5,15h2v-2h-2v2Zm0-4h2v-2h-2v2Zm0,8h2v-2h-2v2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m19,0H5c-1.654,0-3,1.346-3,3v21h20V3c0-1.654-1.346-3-3-3ZM5,2h14c.551,0,1,.448,1,1v2H4v-2c0-.552.449-1,1-1Zm-1,20V7h16v15H4Zm3-12h10v2H7v-2Zm0,4h6v2h-6v-2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                    <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==5 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Manage My Account</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==5" class="w-3 h-3 text-emerald-500 fill-current mx-10" style="margin-left: 2.4rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-10" style="margin-left: 2.4rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Manage My Account</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==3 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(6)">
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg>
                      <span class="text-size-sm text-center">My Profile</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==4 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(7)">
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <svg :class="[selectedSubMenuItem==4 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Delegate Approvals</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==7 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer pb-4' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer pb-4']" @click="barClick(13)">
                      <svg :class="[selectedSubMenuItem==7 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg>
                      <span class="text-size-sm text-center">My Signature</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Activity Liquidation -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==6 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(8)">
              <a href="">
                <div class="flex space-x-2 items-center">
                  <svg :class="[activeListItemIndex==6 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m1 24h22v-21a3 3 0 0 0 -3-3h-16a3 3 0 0 0 -3 3zm2-21a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v19h-18zm2 7h14v-6h-14zm2-4h10v2h-10zm-2 7h2v2h-2zm4 0h2v2h-2zm6 0v2h-2v-2zm-10 4h2v2h-2zm4 0h2v2h-2zm8-4h2v2h-2zm-4 4h6v2h-6z"/></svg>
                  <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==6 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Request Liquidation</span>
                </div>
              </a>
            </div>
             <!-- Aprove Liquidations -->
             <div  v-if="isAnApprover" v-bind="activeListItemIndex" :class="[activeListItemIndex==7 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(9)">
              <a href="">
                <div class="flex items-center">
                  <!-- <svg :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m16 16a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1zm6-5.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z"/></svg> -->
                  <!-- <svg :class="[activeListItemIndex==2 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m11.349,24H0V3C0,1.346,1.346,0,3,0h12c1.654,0,3,1.346,3,3v5.059c-.329-.036-.662-.059-1-.059s-.671.022-1,.059V3c0-.552-.448-1-1-1H3c-.552,0-1,.448-1,1v19h7.518c.506.756,1.125,1.429,1.831,2Zm0-14h-7.349v2h5.518c.506-.756,1.125-1.429,1.831-2Zm-7.349,7h4c0-.688.084-1.356.231-2h-4.231v2Zm20,0c0,3.859-3.141,7-7,7s-7-3.141-7-7,3.141-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5ZM14,5H4v2h10v-2Zm5.589,9.692l-3.228,3.175-1.63-1.58-1.393,1.436,1.845,1.788c.314.315.733.489,1.179.489s.865-.174,1.173-.482l3.456-3.399-1.402-1.426Z"/></svg> -->
                  <div class="flex space-x-2">
                    <!-- <svg :class="[activeListItemIndex==7 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg> -->
                    <svg :class="[activeListItemIndex==7 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m7.896,13.329c.63-.644,1.235-1.481,1.235-2.329H3.868c0,.848.605,1.685,1.235,2.329-2.774.758-5.104,3.646-5.104,6.682,0,2.199,1.794,3.988,4,3.988h5c2.206,0,4-1.789,4-3.988,0-3.037-2.33-5.925-5.104-6.682Zm1.104,7.671h-5c-.551,0-1-.443-1-.988,0-1.886,1.799-3.88,3.5-3.88s3.5,1.995,3.5,3.88c0,.545-.449.988-1,.988Zm15-15h-3v14.5c0,1.826-1.5,3.5-3.5,3.5h-4.037c.743-.827,1.257-1.858,1.448-3h2.589c.276,0,.5-.224.5-.5V6h-10v-2c0-.551-.449-1-1-1s-1,.449-1,1v5h-3v-5C3,1.794,4.794,0,7,0h13.5c1.93,0,3.5,1.57,3.5,3.5v2.5Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==7 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Approve Liquidations</span>
                  </div>
                  <svg v-if="hasPendingLiquidations" class="h-4 w-4 fill-red-400 ml-8" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M20.93,7.3c-.34-1.91-2-3.3-3.94-3.3h-.16c.11-.31,.17-.65,.17-1,0-1.65-1.35-3-3-3h-4c-1.65,0-3,1.35-3,3,0,.35,.06,.69,.17,1h-.16c-1.94,0-3.6,1.39-3.94,3.3L.81,20h7.19c0,2.21,1.79,4,4,4s4-1.79,4-4h7.19l-2.27-12.7ZM9,3c0-.55,.45-1,1-1h4c.55,0,1,.45,1,1s-.45,1-1,1h-4c-.55,0-1-.45-1-1Zm3,19c-1.1,0-2-.9-2-2h4c0,1.1-.9,2-2,2ZM3.19,18l1.85-10.35c.17-.96,1-1.65,1.97-1.65h9.98c.97,0,1.8,.69,1.97,1.65l1.85,10.35H3.19Z"/></svg>
                </div>
              </a>
            </div>
             <!-- Aprove Liquidations -->
             <div v-bind="activeListItemIndex" :class="[activeListItemIndex==12 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(12)">
              <a href="">
                <div class="flex items-center">
                  <!-- <svg :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m16 16a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1zm6-5.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z"/></svg> -->
                  <!-- <svg :class="[activeListItemIndex==2 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m11.349,24H0V3C0,1.346,1.346,0,3,0h12c1.654,0,3,1.346,3,3v5.059c-.329-.036-.662-.059-1-.059s-.671.022-1,.059V3c0-.552-.448-1-1-1H3c-.552,0-1,.448-1,1v19h7.518c.506.756,1.125,1.429,1.831,2Zm0-14h-7.349v2h5.518c.506-.756,1.125-1.429,1.831-2Zm-7.349,7h4c0-.688.084-1.356.231-2h-4.231v2Zm20,0c0,3.859-3.141,7-7,7s-7-3.141-7-7,3.141-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5ZM14,5H4v2h10v-2Zm5.589,9.692l-3.228,3.175-1.63-1.58-1.393,1.436,1.845,1.788c.314.315.733.489,1.179.489s.865-.174,1.173-.482l3.456-3.399-1.402-1.426Z"/></svg> -->
                  <div class="flex space-x-2">
                    <!-- <svg :class="[activeListItemIndex==7 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==12 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m7.896,13.329c.63-.644,1.235-1.481,1.235-2.329H3.868c0,.848.605,1.685,1.235,2.329-2.774.758-5.104,3.646-5.104,6.682,0,2.199,1.794,3.988,4,3.988h5c2.206,0,4-1.789,4-3.988,0-3.037-2.33-5.925-5.104-6.682Zm1.104,7.671h-5c-.551,0-1-.443-1-.988,0-1.886,1.799-3.88,3.5-3.88s3.5,1.995,3.5,3.88c0,.545-.449.988-1,.988Zm15-15h-3v14.5c0,1.826-1.5,3.5-3.5,3.5h-4.037c.743-.827,1.257-1.858,1.448-3h2.589c.276,0,.5-.224.5-.5V6h-10v-2c0-.551-.449-1-1-1s-1,.449-1,1v5h-3v-5C3,1.794,4.794,0,7,0h13.5c1.93,0,3.5,1.57,3.5,3.5v2.5Z"/>
                    </svg> -->
                    <!-- <svg :class="[activeListItemIndex==12 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m16.242,1.757c-1.134-1.133-2.641-1.757-4.242-1.757s-3.11.624-4.242,1.757c-1.134,1.133-1.758,2.64-1.758,4.243s.624,3.11,1.77,4.254l4.23,4.138,4.242-4.149c1.134-1.133,1.758-2.64,1.758-4.243s-.624-3.11-1.758-4.243Zm-4.242,6.243c-1.105,0-2-.895-2-2s.895-2,2-2,2,.895,2,2-.895,2-2,2Zm12,7.701v1.099l-12,7.2L0,16.8v-1.099l6.494-3.897,2.217,2.169-3.796,2.277,7.084,4.25,7.084-4.25-3.796-2.277,2.217-2.168,6.494,3.896Z"/>
                    </svg> -->
                    <svg :class="[activeListItemIndex==12 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m16.927,10.175c.572.559,1.322.838,2.073.838s1.501-.279,2.073-.838c.024-.024,1.462-1.64,1.462-1.64.945-.944,1.465-2.2,1.465-3.536s-.52-2.591-1.465-3.536c-.944-.944-2.2-1.464-3.535-1.464s-2.591.52-3.536,1.464c-1.949,1.95-1.949,5.122-.059,7.008,0,0,1.497,1.678,1.521,1.703Zm-11.927,11.325c0,1.381-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5,1.119-2.5,2.5-2.5,2.5,1.119,2.5,2.5Zm7.5-8.5c1.381,0,2.5,1.119,2.5,2.5s-1.119,2.5-2.5,2.5-2.5-1.119-2.5-2.5,1.119-2.5,2.5-2.5Zm-6,4c-2.481,0-4.5-2.019-4.5-4.5s2.019-4.5,4.5-4.5h6c.829,0,1.5.671,1.5,1.5s-.671,1.5-1.5,1.5h-6c-.827,0-1.5.673-1.5,1.5s.673,1.5,1.5,1.5,1.5.671,1.5,1.5-.671,1.5-1.5,1.5Zm17.5,1.5c0,2.481-2.019,4.5-4.5,4.5h-11c-.829,0-1.5-.671-1.5-1.5s.671-1.5,1.5-1.5h11c.827,0,1.5-.673,1.5-1.5s-.673-1.5-1.5-1.5h-1c-.829,0-1.5-.671-1.5-1.5s.671-1.5,1.5-1.5h1c2.481,0,4.5,2.019,4.5,4.5Zm-2.999-13.713c0,1.105-.895,2-2,2s-2-.895-2-2,.895-2,2-2,2,.895,2,2Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==12 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Liquidation Timeline</span>
                  </div>
                </div>
              </a>
            </div>
            <!-- divider line -->
            <div class=" h-2 w-full border-t"></div>
            <div  class="px-4 color: #42b983"  @click="barClick(100)">
              <a href="#">
                <div class="flex space-x-2 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500 fill-current" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M15,2.426v2.1a9,9,0,1,1-6,0v-2.1a11,11,0,1,0,6,0Z"/><rect x="11" width="2" height="8"/></g></svg>
                  <span class="font-medium text-size-sm  text-gray-600">Signout</span>
                </div>
              </a>
            </div>
          </nav>
        </div>
  </template>
  
  <script>
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { mapState } from 'vuex'
  import { ajax } from 'rxjs/ajax'
  import { retry, zip } from 'rxjs'
  
  export default {
    computed: {
      ...mapState([
        'id',
        'activeListItemIndex',
        'selectedSubMenuItem',
        'email',
        'loginId',
        'token',
        'sidebarClosed'
      ])
    },
    components: {
      Popover,
      PopoverButton,
      PopoverPanel
    },
    setup () {
      return {
      }
    },
    name: 'side-bar',
    props: {
    },
    data: () => {
      return {
        proxyData: [],
        hasPendingRequests: false,
        hasPendingLiquidations: false,
        isAnApprover: false,
        BASE_URL: process.env.VUE_APP_BASE_URL
      }
    },
    mounted () {
      this.supervisorCheck()
      this.outsideSidebarClick()
      this.getEmployeeProxies()
    },
    methods: {
      // get the employees he is allowed to approve requests on their behalf
      getEmployeeProxies() {
          const observable = ajax({
            url: process.env.VUE_APP_BASE_URL + '/proxy/findByProxyId?id=' + this.id,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          })
          const observer = {
            next: (response) => {
              const data = response.response
              const size = data.length
              const proxyData = []

              for (let i = 0; i < size; i++) {
                const employeeId = data[i].employee_id
                proxyData.push(employeeId)
              }

              this.proxies = proxyData

              // getting the pending activity approvals
              this.geEmployeeRequest()
              this.getLiquidations()
            },
            error: () => {},
            complete: () => {}
          }

          observable.subscribe(observer)
      },
      // getting the pending activity approvals
      geEmployeeRequest () {
        const observables = []
        const approvers = [...this.proxies, this.id]
        const size = approvers.length

        for (let i = 0; i < size; i++) {
          const approverId = approvers[i]
          const observableData = ajax({
            url: this.BASE_URL + '/request/findToApprove?employeeId='+approverId,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          }).pipe(
            retry(1)
          )

          observables.push(observableData)
        }

        const observable = zip(...observables)

        const observer = {
          next: (response) => {
            const data = response
            const size = data.length
            
            for (let i = 0; i < size; i++) {
              const dataItem = data[i].response
              const dataSize = dataItem.length

              if (dataSize > 0) {
                this.hasPendingRequests = true
                break
              }
            }
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)
      },
      // getting the pending activity approvals
      getLiquidations () {
        this.requestData = []
        this.showRequests = false
        const observables = []
        const approvers = [...this.proxies, this.id]
        const size = approvers.length

        for (let i = 0; i < size; i++) {
          const approverId = approvers[i]

          const observableData = ajax({
            url: this.BASE_URL + '/liquidation/findToApprove?employeeId=' + approverId,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          }).pipe(
            retry(1)
          )

          observables.push(observableData)
        }

        const observable = zip(observables)

        const observer = {
          next: (response) => {
            const data = response
            const size = data.length

            for (let i = 0; i < size; i++) {
              const dataItem = data[i].response
              const dataSize = dataItem.length
              
              if (dataSize > 0) {
                this.hasPendingLiquidations = true
                break
              }
            }
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)
      },
      outsideSidebarClick () {
        if (this.$isMobile()) {
          document.addEventListener('click', (event) => {
            const sidebar = document.getElementById('sidebar-x')
            const navbar = document.getElementById('navbar-x')

            if (sidebar !== null && navbar !== null && !this.sidebarClosed) {
              if (!sidebar.contains(event.target) && !navbar.contains(event.target)) {
                const sidebar = document.querySelector('.sidebar')
                sidebar.classList.toggle('-translate-x-full')
                this.$store.commit('mutateSidebarClosed', true)
                this.$emit('sidebarDismissed', true)
              }
            }
          })
        }
      },
      // check if the employee is a supervisor
      supervisorCheck () {
        const observable = ajax({
          url: this.BASE_URL + '/employee/supervisorCheck?id=' + this.id,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })

        const observer = {
          next: (response) => {
            const data = response.response
            const size = data.length

            if (size > 0) {
              this.isAnApprover = true
            }
          },
          error: () => {},
          complete: () => {}
        }

        observable.subscribe(observer)
      },
      // signout of the system
      // logs out users
      logout () {
        const observable = ajax({
          url: this.BASE_URL + '/login/signout',
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          body: {
            id: this.loginId
          }
        })

        const observer = {
          next: () => {
          },
          error: () => {
            this.$router.push('/')
          },
          complete: () => {
            this.$router.push('/')
          }
        }
  
        observable.subscribe(observer)
      },
      // side nav bar list items clicks
      barClick (index) {
        switch (index) {
          case 0:
            this.$router.push('/employee-home')
            this.$store.commit('mutateActiveListItemIndex', 0)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            break
          case 1:
            this.$store.commit('mutateActiveListItemIndex', 1)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            this.$router.push('/create-memo')
            break
          case 2:
            this.$store.commit('mutateActiveListItemIndex', 2)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            this.$router.push('/pending-approval')
            break
          case 3:
            this.$store.commit('mutateActiveListItemIndex', 3)
            this.$store.commit('mutateSelectedSubMenuItem', 3)
            this.$router.push('/timeline')
            break
          case 4:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 1)
            this.$router.push('/approval-history')
            break
          case 5:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 2)
            this.$router.push('/request-history')
            break
          case 6:
            this.$store.commit('mutateActiveListItemIndex', 5)
            this.$store.commit('mutateSelectedSubMenuItem', 3)
            this.$router.push('/profile')
            break
          case 7:
            this.$store.commit('mutateActiveListItemIndex', 5)
            this.$store.commit('mutateSelectedSubMenuItem', 4)
            this.$router.push('/proxy-management')
            break
          case 8:
            this.$store.commit('mutateActiveListItemIndex', 6)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            this.$router.push('/activity-closure')
            break
          case 9:
            this.$store.commit('mutateActiveListItemIndex', 7)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            this.$router.push('/pending-liquidation')
            break
          case 10:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 5)
            this.$router.push('/liquidation-history')
            break
          case 11:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 6)
            this.$router.push('/liquidation-approval-history')
            break
          case 12:
            this.$store.commit('mutateActiveListItemIndex', 12)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            this.$router.push('/liquidation-timeline')
            break
          case 13:
            this.$store.commit('mutateActiveListItemIndex', 5)
            this.$store.commit('mutateSelectedSubMenuItem', 7)
            this.$router.push('/signature')
            break
          default:
            // signout
            this.logout()
        }
      }
    }
  }
  </script>
  
  <style>
  </style>
  