<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
          <!-- the top route and username grid -->
          <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">>Home</span> > Edit Schedule</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <!-- Timeline -->
            <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
              <!-- The chart title -->
              <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
                <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
                <svg class="h-4 w-4 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M19,2H18V1a1,1,0,0,0-2,0V2H8V1A1,1,0,0,0,6,1V2H5A5.006,5.006,0,0,0,0,7V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2ZM2,7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3V8H2ZM19,22H5a3,3,0,0,1-3-3V10H22v9A3,3,0,0,1,19,22Z"/><circle cx="12" cy="15" r="1.5"/><circle cx="7" cy="15" r="1.5"/><circle cx="17" cy="15" r="1.5"/></svg>
                <p class="text-left text-size-md font-medium text-gray-600">Edit Schedule</p>
              </div>
              <!-- The activity creation steps -->
              <div class="flex items-center col-span-full mx-5 mt-5">
                <span class="ml-4 mr-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="mx-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="mx-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">3</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="cursor-pointer animate-pulse mx-0.5 text-size-sm ring-emerald-600 bg-emerald-600 ring-2 text-white py-0 px-1">4</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="cursor-pointer text-size-sm mr-4 ring-emerald-600 ring-2 text-gray-500 py-0 px-1">5</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="cursor-pointer text-size-sm mr-4 ring-emerald-600 ring-2 text-gray-500 py-0 px-1">6</span>
              </div>
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-2 sm:gap-y-1.5">
                <div class="space-x-0.5 text-right mb-2">
                  <button @click="clearSchedule()" class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Delete All</button>
                  <button @click="openAddScheduleModal()" class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Add Details</button>
                </div>
                <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class="bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Start Date</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">End Date</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Description</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Place</td>
                        <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody  v-if="showSchedule">
                      <tr v-for="(data, i) in scheduleData" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ formatData(data.startDate) }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.endDate) }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.place.name }}</td>
                        <td class="py-3 px-2 border text-center flex">
                          <div class="flex mx-auto space-x-4">
                            <svg @click="deleteItem(i)" class="h-5 w-5 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <!-- <svg @click="loadEditScheduleModal(i)" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m21,0H3C1.346,0,0,1.346,0,3v21h24V3c0-1.654-1.346-3-3-3Zm-2.879,10.621l-7.878,7.879h-4.243v-4.242l7.879-7.879c1.133-1.133,3.109-1.133,4.243,0,1.169,1.17,1.169,3.072,0,4.242Zm-3.871,1.043l-4.836,4.836h-1.415v-1.414l4.836-4.836,1.414,1.414Zm2.457-3.871c.39.39.39,1.024,0,1.414l-1.043,1.043-1.414-1.414,1.043-1.043c.377-.379,1.036-.379,1.414,0Z"/></svg> -->
                            <svg @click="loadEditScheduleModal(i)" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="col-span-full sm:col-span-1">
              <div class="flex-1 mb-10 space-x-0.5">
                <button
                  @click="back()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Back
                </button>
                <button
                  @click="next()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Next
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
      <!-- add schedule modal -->
      <TransitionRoot appear :show="isAddScheduleOpen" @close="closeAddScheduleModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 opacity-80 bg-black" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Add a Schedule
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Description -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          requiemerald
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Place -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">District(s) <span class="font-bold text-red-500">*</span></label>
                      <div class=" w-full">
                        <CustomDropDown
                          @selectedItems="(data) => { place = data}"
                          :elementId="'place'"
                          :url="'/district/search'"
                          :persistedSelection="place"
                          :multipleSelection="false"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                    <!-- Start date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Start Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="startDate"
                          requiemerald
                          type="date"
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- End date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">End Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="endDate"
                          requiemerald
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddScheduleModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="addScheduleData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- edit schedule modal -->
      <TransitionRoot appear :show="isEditScheduleOpen" @close="closeEditScheduleModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 opacity-80 bg-black" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Edit a Schedule
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Description -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          requiemerald
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Place -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">District(s) <span class="font-bold text-red-500">*</span></label>
                      <div class=" w-full">
                        <CustomDropDown
                          @selectedItems="(data) => { place = data }"
                          :elementId="'place'"
                          :url="'/district/search'"
                          :persistedSelection="place"
                          :multipleSelection="false"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                    <!-- Start date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Start Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="startDate"
                          requiemerald
                          type="date"
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- End date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">End Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="endDate"
                          requiemerald
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeEditScheduleModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-600">
                      Cancel
                    </button>
                    <button
                      @click="editScheduleData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-600">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- the pop up modal -->
      <PopUp 
        ref="popupRef"
        :message="saveMessage" />
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/Navbar.vue'
  import Sidebar from '@/components/SideBar.vue'
  import MessageModal from './MessageModal.vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  import { mapState } from 'vuex'
  import { ref } from 'vue'
  import { interval } from 'rxjs'
  import CustomDropDown from './CustomDropDown.vue'
  import PopUp from './PopUp.vue'
  
  export default {
    computed: {
      ...mapState([
        'firstname',
        'surname',
        'token',
        'editSchedule'
      ])
    },
    components: {
      PopUp,
      Navbar,
      Sidebar,
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      MessageModal,
      CustomDropDown
    },
    setup () {
      const isAddScheduleOpen = ref(false)
      const isEditScheduleOpen = ref(false)
      return {
        isAddScheduleOpen,
        isEditScheduleOpen,
        closeAddScheduleModal () {
          isAddScheduleOpen.value = false
        },
        openAddScheduleModal () {
          isAddScheduleOpen.value = true
        },
        closeEditScheduleModal () {
          isEditScheduleOpen.value = false
        },
        openEditScheduleModal () {
          isEditScheduleOpen.value = true
        }
      }
    },
    name: 'create-schedule',
    props: {
    },
    data: () => {
      return {
        isOk: true,
        messageTitle: '',
        message: '',
        scheduleData: [],
        startDate: '',
        endDate: '',
        description: '',
        place: [],
        showSchedule: false,
        expandBody: false,
        selectedEditIndex: -1,
        saveMessage: process.env.VUE_APP_SAVE_MESSAGE,
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      }
    },
    mounted () {
      // removing the submenu items selection
      // this.$store.commit('mutateActiveListItemIndex', 1)
      // this.$store.commit('mutateSelectedSubMenuItem', null)
      // load the stoemerald schedule
      this.loadSchedule()
      // start automatically saving the data
      this.autosave()
    },
    methods: {
      autosave() {
        this.$refs.popupRef.show()
      const observable = interval(1000) // Emit every second
      const observer = {
        next: () => {
          this.$store.commit('mutateEditSchedule', this.scheduleData)
        },
        error: () => {}
      }
      observable.subscribe(observer)
    },
      // edit a schedule row
      editScheduleData () {
        // check if the details are empty
        if (this.description !== '' && this.place !== undefined && this.startDate !== '' && this.endDate !== '') {
          // hide the schedule
          this.showSchedule = false
          // adding the data
          const rowData = {
            startDate: this.startDate,
            endDate: this.endDate,
            description: this.description,
            place: this.place
          }
          // edit it in the schedule
          this.scheduleData[this.selectedEditIndex] = rowData
          // show the schedule
          this.showSchedule = true
          //  close the add a schedule modal
          this.closeEditScheduleModal()
        }
      },
      // deletes an item at an index
      deleteItem (index) {
        this.showSchedule = false
        this.scheduleData.splice(index, 1)
        this.showSchedule = true
      },
      // loads the edit schedule modal
      loadEditScheduleModal (index) {
        this.selectedEditIndex = index
        const rowData = this.scheduleData[index]
        this.startDate = rowData.startDate
        this.endDate = rowData.endDate
        this.description = rowData.description
        this.place = rowData.place
        // open the edit schedule moda
        this.openEditScheduleModal()
      },
      // loads the stoemerald schedule
      loadSchedule () {
        if (this.schedule !== null) {
          this.showSchedule = false
          this.scheduleData = this.editSchedule
          this.showSchedule = true
        }
      },
      // format the table data
      formatData (data) {
        var formattedData
        // check if it is a number
        if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
          // format the number to a currency format
          var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
          formattedData = currencyFormat.format(String(data)).substring(4)
        } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
          // check if the data is a date
          // formating the dates supplied
          var textDate = new Date(data)
          var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
          formattedData = formattedTextDate
        } else {
          formattedData = data
        }
        return formattedData
      },
      // add a schedule data
      addScheduleData () {
        // check if the details are empty
        if (this.description !== '' && this.place !== undefined && this.startDate !== '' && this.endDate !== '') {
          // hide the schedule
          this.showSchedule = false
          // adding the data
          const rowData = {
            startDate: this.startDate,
            endDate: this.endDate,
            description: this.description,
            place: this.place
          }
          // add it in the schedule
          this.scheduleData.push(rowData)
          // show the schedule
          this.showSchedule = true
          //  close the add a schedule modal
          this.closeAddScheduleModal()
        }
      },
      // clear the schedule
      clearSchedule () {
        this.scheduleData = []
      },
      back () {
        // moving to the previous step
        this.$router.push('edit-budget')
      },
      next () {
        if (this.scheduleData.length !== 0) {
          this.$router.push('edit-attachment')
        } else {
          // showing the dialog for the error
          this.isOk = true
          this.messageTitle = 'Message'
          this.message = 'Please fill the schedule to move to the next step...'
          this.$refs.messageModal.openMessageModal()
        }
      },
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      }
    }
  }
  </script>
  
  <style>
  
  #chart-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 40vh;
  }
  
  </style>
  