<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-40"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
          <span><span class=" text-emerald-500">>Home</span> > Memo Creation</span>
          <span>{{ firstname }} {{ surname }}</span>
        </div>
          <div>
          <!-- Timeline -->
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m14.414 0h-9.414a3 3 0 0 0 -3 3v21h20v-16.414zm.586 3.414 3.586 3.586h-3.586zm-11 18.586v-19a1 1 0 0 1 1-1h8v7h7v13zm9-8h3v2h-3v3h-2v-3h-3v-2h3v-3h2z"/></svg> -->
              <svg class="h-4 w-4 fill-emerald-500" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m18,0H6c-1.654,0-3,1.346-3,3v21h18V3c0-1.654-1.346-3-3-3Zm1,22H5V3c0-.552.448-1,1-1h12c.552,0,1,.448,1,1v19ZM7,5h10v2H7v-2Zm0,5h10v2H7v-2Zm0,5h5v2h-5v-2Z"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600">Create Memo</p>
            </div>
            <!-- The activity creation steps -->
            <div class="flex items-center col-span-full mx-5 mt-5">
              <span class="ml-4 mr-0.5 animate-pulse cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
              <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="mx-0.5 cursor-pointer text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">2</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="mx-0.5 cursor-pointer text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">3</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">4</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm ring-emerald-600 font-medium ring-2 text-gray-500 py-0 px-1">5</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer text-size-sm font-medium mr-4 ring-emerald-600 ring-2 text-gray-500 py-0 px-1">6</span>
            </div>
            <div v-if="dataLoaded" class="mx-8 mb-5 mt-5 border shadow px-4 py-8 grid grid-flow-row grid-cols-2 grid-rows-3 gap-x-4 gap-y-6 sm:gap-y-6  ">
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Request Type <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'requestTypeId'"
                      :url="'/request-type/search'"
                      :persistedSelection="selectedRequestType"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedRequestType = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Activity Title <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <input
                    v-model="title"
                    placeholder="Enter activity title"
                    required
                    type="text"
                    class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Source of Funding <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'budgetSource'"
                      :url="'/budget-source/search'"
                      :persistedSelection="selectedBudgetSources"
                      :multipleSelection="true"
                      @selectedItems="(items) => {
                        selectedBudgetSources = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Activity Type <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'activityType'"
                      :url="'/activity-type/search'"
                      :persistedSelection="selectedActivityType"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedActivityType = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Activity Stakeholders <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'activityStakeholder'"
                      :url="'/stakeholder/search'"
                      :persistedSelection="selectedActivityStakeholders"
                      :multipleSelection="true"
                      @selectedItems="(items) => {
                        selectedActivityStakeholders = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Activity Specific Objectives <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'specificObjective'"
                      :url="'/specific-objective/search'"
                      :persistedSelection="selectedSpecificObjective"
                      :multipleSelection="true"
                      @selectedItems="(items) => {
                        selectedSpecificObjective = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Destination <span class="font-bold text-red-500">*</span></label>
                <div class="flex w-full">
                  <div class="w-full">
                    <OfflineDropDown
                      :elementId="'destination'"
                      :persistedSelection="selectedDestination"
                      :buttonLabel="'Select the Destination'"
                      :multipleSelection="false"
                      :dataSource="destinations"
                      @selectedItems="(items) => {
                        selectedDestination = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Request Currency <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'currencyId'"
                      :url="'/currency/search'"
                      :persistedSelection="selectedCurrency"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedCurrency = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name', 'acronym']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Book Accommodation <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'accommodationId'"
                      :url="'/choice/search'"
                      :persistedSelection="selectedAccommodation"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedAccommodation = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Book Transportation <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'transport'"
                      :url="'/choice/search'"
                      :persistedSelection="selectedTransportation"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedTransportation = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Book Conferencing <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'conference'"
                      :url="'/choice/search'"
                      :persistedSelection="selectedConference"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedConference = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Is The Activity Budgeted? <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'budgetedId'"
                      :url="'/choice/search'"
                      :persistedSelection="selectedBudgeted"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedBudgeted = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Is The Activity In Your Workplan? <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'plannedId'"
                      :url="'/choice/search'"
                      :persistedSelection="selectedPlanned"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedPlanned = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Expected Number of Participants <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <input
                    v-model="participants"
                    placeholder="Enter expected no of participants"
                    requiemerald
                    class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Additional Notes <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <input
                    v-model="notes"
                    placeholder="Enter additional notes"
                    requiemerald
                    class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                </div>
              </div>
            </div>
            <div class="col-span-full sm:col-span-1">
              <div class="flex-1 mb-10 space-x-0.5">
                <button
                  v-if="selectedAccommodation.name === 'Yes' || selectedConference.name === 'Yes'"
                  @click="viewPR()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    PR Form
                </button>
                <button
                  @click="viewPercentages()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Budget Percentages
                </button>
                <button
                  @click="next()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Next
                </button>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the message modal -->
    <MessageModal
      :isOk="isOk"
      :title="messageTitle"
      :message="message"
      ref="messageModal"
    />
    <!-- the pop up modal -->
    <PopUp 
      ref="popupRef"
      :message="saveMessage" />
    <!-- the warning modal -->
    <WarningModal
      :isOk="isOk"
      @click="(event) => {
        warningHandler(event)
      }"
      :title="'Request Submission Error'"
      :event="'close'"
      :message="'You cannot submit a new request because you have an active request that is still active.'"
      ref="warningModal"
    />
     <!-- the budget percentage modal -->
     <BudgetPercentageModal
      v-if="showPercentageModal"
      :isOk="isOk"
      :title="messageTitle"
      :message="message"
      :budgetSources="selectedBudgetSources"
      ref="budgetPercentageModal"
      @error="(error) => {
        percentageError(error)
      }"
      @success="(percentageData) => {
        savePercentageData(percentageData)
      }"
    />
    <!-- the pr modal -->
    <PRModal ref="prModal" :conferencing="selectedConference" :accommodation="selectedAccommodation"/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/SideBar.vue'
import MessageModal from './MessageModal.vue'
import { mapState } from 'vuex'
import OfflineDropDown from './OfflineDropDown.vue'
import CustomDropDown from './CustomDropDown.vue'
import { interval } from 'rxjs'
import PopUp from './PopUp.vue'
import WarningModal from './WarningModal.vue'
import { ajax } from 'rxjs/ajax'
import BudgetPercentageModal from './BudgetPercentageModal.vue'
import PRModal from './PRModal.vue'

export default {
  computed: {
    ...mapState([
      'id',
      'firstname',
      'surname',
      'memo',
      'token',
      'pr'
    ])
  },
  components: {
    PopUp,
    Navbar,
    Sidebar,
    MessageModal,
    CustomDropDown,
    OfflineDropDown,
    WarningModal,
    BudgetPercentageModal,
    PRModal
  },
  setup () {
    return {
    }
  },
  name: 'create-memo',
  props: {
  },
  data: () => {
    return {
      isOk: true,
      message: '',
      messageTitle: '',
      startDate: '',
      endDate: '',
      title: '',
      objective: '',
      noOfParticipants: 1,
      notes: '',
      keyContacts: '',
      expandBody: false,
      dataLoaded: false,
      selectedBudgetSources: {},
      selectedActivityType: {},
      selectedActivityStakeholders: {},
      selectedSpecificObjective: {},
      selectedCurrency: {},
      selectedDestination: {},
      selectedAccommodation: {},
      selectedTransportation: {},
      selectedConference: {},
      selectedOffice: {},
      selectedRequestType: {},
      selectedBudgeted: {},
      selectedPlanned: {},
      showPercentageModal: false,
      saveMessage: process.env.VUE_APP_SAVE_MESSAGE,
      destinations: [
        { id: 1, name: 'Maseru' },
        { id: 2, name: 'Local Outside Maseru' },
        { id: 3, name: 'Abroad / Outside Lesotho' }
      ],
      BASE_URL: process.env.VUE_APP_BASE_URL
    }
  },
  mounted () {
    // removing the submenu items selection
    // this.$store.commit('mutateActiveListItemIndex', 1)
    // this.$store.commit('mutateSelectedSubMenuItem', null)
    // // load the vuex data
    this.loadMemoData()
    // start automatically saving the data
    this.autosave()
    // check if the employee has peding requests
    this.pendingRequestCheck()
    // this.$refs.prModal.openPRModal() 
  },
  methods: {
    budgetPercentageCheck () {
        try {
        if (Array.isArray(this.selectedBudgetSources)) {
          const size = this.selectedBudgetSources.length
          var total = 0
          for (let i = 0; i < size; i++) {
            const data = this.selectedBudgetSources[i]
            const percent = data.percentage

            if (percent === undefined || percent === null) {
              return false
            } else {
              total += parseFloat(percent)
            }
          }
        }
        // const siz\e
        total = parseInt(total)
        if (total !== 100) {
          return false
        } else {
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    isPRFilled () {
      //  check if the accommodation details are filled
      if (this.selectedAccommodation.name === 'Yes' && this.selectedConference.name === 'No') {
        if (this.pr.budgetLine !== undefined && this.pr.budgetAmount !== undefined && this.pr.districtName.id !== undefined
            && this.pr.area !== undefined && this.pr.specification !== undefined && this.pr.checkInDate !== undefined
            && this.pr.checkOutDate !== undefined && this.pr.singleRooms !== undefined && this.pr.doubleRooms !== undefined && this.pr.deliveryDate !== undefined

            && this.pr.budgetLine !== '' && this.pr.budgetAmount !== '' && this.pr.districtName.id !== ''
            && this.pr.area !== '' && this.pr.specification !== '' && this.pr.checkInDate !== ''
            && this.pr.checkOutDate !== '' && this.pr.singleRooms !== '' && this.pr.doubleRooms !== '' && this.pr.deliveryDate !== ''
          ) {
            return true
        } else {
          return false
        }
      }
      // check if the conference details are filled
      else if (this.selectedAccommodation.name === 'No' && this.selectedConference.name === 'Yes') {
        if (this.pr.budgetLine !== undefined && this.pr.budgetAmount !== undefined && this.pr.districtName.id !== undefined
            && this.pr.area !== undefined && this.pr.specification !== undefined && this.pr.confPackage !== undefined
            && this.pr.Qparticipants !== undefined && this.pr.noParticipants !== undefined && this.pr.days !== undefined && this.pr.deliveryTime !== undefined && this.pr.deliveryDate !== undefined

            && this.pr.budgetLine !== '' && this.pr.budgetAmount !== '' && this.pr.districtName.id !== ''
            && this.pr.area !== '' && this.pr.specification !== '' && this.pr.confPackage !== ''
            && this.pr.Qparticipants !== '' && this.pr.noParticipants !== '' && this.pr.days !== '' && this.pr.deliveryTime !== '' && this.pr.deliveryDate !== ''
          ) {
            return true
        } else {
          return false
        }
      }
      // else if the person has booked both
      else {
        if (this.pr.budgetLine !== undefined && this.pr.budgetAmount !== undefined && this.pr.districtName.id !== undefined
            && this.pr.area !== undefined && this.pr.specification !== undefined && this.pr.confPackage !== undefined
            && this.pr.Qparticipants !== undefined && this.pr.noParticipants !== undefined && this.pr.days !== undefined && this.pr.deliveryTime !== undefined && this.pr.deliveryDate !== undefined
            && this.pr.checkInDate !== undefined && this.pr.checkOutDate !== undefined && this.pr.singleRooms !== undefined && this.pr.doubleRooms !== undefined

            && this.pr.budgetLine !== '' && this.pr.budgetAmount !== '' && this.pr.districtName.id !== ''
            && this.pr.area !== '' && this.pr.specification !== '' && this.pr.confPackage !== ''
            && this.pr.Qparticipants !== '' && this.pr.noParticipants !== '' && this.pr.days !== '' && this.pr.deliveryTime !== '' && this.pr.deliveryDate !== ''
            && this.pr.checkInDate !== ''
            && this.pr.checkOutDate !== '' && this.pr.singleRooms !== '' && this.pr.doubleRooms !== '' 
          ) {
            return true
        } else {
          return false
        }
        
      }
    },
    viewPR () {
      this.$refs.prModal.openPRModal()  
    },
    viewPercentages () {
      this.showPercentageModal = true
      setTimeout(() => {
        this.$refs.budgetPercentageModal.openMessageModal()      
      }, 200)
    },
    // save the percentage details
    savePercentageData (data) {
      this.showPercentageModal = false
      this.selectedBudgetSources = data
    },
    // shows a percentage error message
    percentageError(message) {
      this.showPercentageModal = false
      this.isOk = false
      this.messageTitle = 'Error Message'
      this.message = message
      this.$refs.messageModal.openMessageModal()
      console.log('error')
    },
    // pending requests check
    pendingRequestCheck () {
      const observable = ajax({
        url: this.BASE_URL + '/request/findUnliquidated?employeeId=' + this.id,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      
      const observer = {
        next: (response) => {
          const data = response.response
          const size = data.length
          if (size > 0) {
            this.$refs.warningModal.openMessageModal()
          }
        },
        error: () => {},
        complete: () => {}
      }

      observable.subscribe(observer)
    },
    warningHandler (event) {
      // move the user to the home page
      if (event.toString() === 'close') {
        this.$router.push('/employee-home')
      }
    },
    autosave() {
      this.$refs.popupRef.show()
      const observable = interval(1000) // Emit every second
      const observer = {
        next: () => {
          const dataToSave = {
            title: this.title,
            budgetSource: this.selectedBudgetSources,
            requestType: this.selectedRequestType,
            activityType: this.selectedActivityType,
            stakeholders: this.selectedActivityStakeholders,
            specificObjectives: this.selectedSpecificObjective,
            currency: this.selectedCurrency,
            accommodation: this.selectedAccommodation,
            transportation: this.selectedTransportation,
            conferencing: this.selectedConference,
            budgeted: this.selectedBudgeted,
            planned: this.selectedPlanned,
            expectedParticipants: this.participants,
            notes: this.notes,
            destination: this.selectedDestination
          }
          this.$store.commit('mutateMemo', dataToSave)
        },
        error: () => {}
      }
      observable.subscribe(observer)
    },
    openMessageModal () {
      this.isOk = false
      this.messageTitle = 'Error Message'
      this.message = 'Fill all the fields in the memo to move to the next step...'
      this.$refs.messageModal.openMessageModal()
    },
    // load the stoemerald memo data
    loadMemoData () {
      if (this.memo !== null) {
        this.title = this.memo.title
        this.participants = this.memo.expectedParticipants
        this.notes = this.memo.notes
        this.selectedBudgetSources = this.memo.budgetSource
        this.selectedRequestType = this.memo.requestType
        this.selectedActivityType = this.memo.activityType
        this.selectedActivityStakeholders = this.memo.stakeholders
        this.selectedSpecificObjective = this.memo.specificObjectives
        this.selectedCurrency = this.memo.currency
        this.selectedAccommodation = this.memo.accommodation
        this.selectedTransportation = this.memo.transportation
        this.selectedConference = this.memo.conferencing
        this.selectedPlanned = this.memo.planned
        this.selectedBudgeted = this.memo.budgeted
        
        if (this.memo.destination.id !== undefined && this.memo.destination.id !== null) {
          this.selectedDestination = this.memo.destination
        }
        
        this.dataLoaded = true
      } else {
        this.dataLoaded = true
      }
    },
    next () {
      // moving to the next step
      // check if the employee has filled the PR details
      if ((this.selectedAccommodation.name === 'Yes' || this.selectedConference.name === 'Yes') && !this.isPRFilled()) {
        this.viewPR()
      }
      // check the budget percentages
      else if (!this.budgetPercentageCheck()) {
        // showing the dialog for the error
        this.isOk = true
        this.messageTitle = 'Message'
        this.message = 'Check the budget percentage, make sure its total is 100%...'
        this.$refs.messageModal.openMessageModal()
      }
      // cehck the individual fields
      else if (
        this.title.trim() !== '' &&
        !isNaN(this.participants) &&
        this.notes.trim() !== '' &&
        Array.isArray(this.selectedBudgetSources) &&
        this.selectedRequestType.id !== undefined &&
        this.selectedActivityType.id !== undefined &&
        Array.isArray(this.selectedActivityStakeholders) &&
        Array.isArray(this.selectedSpecificObjective) && 
        this.selectedCurrency.id !== undefined &&
        this.selectedAccommodation.id !== undefined &&
        this.selectedTransportation.id !== undefined &&
        this.selectedConference.id !== undefined &&
        this.selectedPlanned.id !== undefined &&
        this.selectedBudgeted.id !== undefined
      ) {
        this.$router.push('create-budget')
      } else {
        // showing the dialog for the error
        this.isOk = true
        this.messageTitle = 'Message'
        this.message = 'Please fill all the fields to move to the next step...'
        this.$refs.messageModal.openMessageModal()
      }
      
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
