<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
          <!-- the top route and username grid -->
          <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">>Home</span> >Delegate Approvals</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <!-- Timeline -->
            <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
              <!-- The chart title -->
              <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
                <!-- <svg class="h-4 w-4 text-emerald-500 fill-current"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-6a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm7.5 13.5a5.506 5.506 0 0 0 -5.5-5.5h-4a5.506 5.506 0 0 0 -5.5 5.5v3.5h3v-3.5a2.5 2.5 0 0 1 2.5-2.5h4a2.5 2.5 0 0 1 2.5 2.5v3.5h3zm2.5-11.5a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-6a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm6.5 13.5a5.506 5.506 0 0 0 -5.5-5.5h-3.5v3h3.5a2.5 2.5 0 0 1 2.5 2.5v3.5h3z"/></svg> -->
                <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                  <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                </svg>
                <p class="text-left text-size-md font-medium text-gray-600">Delegate Request Approvals</p>
              </div>
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-4">
                <!-- the button for adding approval delegates -->
                <div class="flex justify-between">
                  <span></span>
                  <div class="my-auto">
                    <button @click="deleteAllProxies()" class="shadow inline-block box-shadow text-size-sm px-20 py-2 bg-red-400  text-white font-medium leading-tight transform focus:bg-red-400 focus:outline-none focus:ring-0 active:bg-emerald-700 transition duration-150 ease-in-out">Remove All Proxies</button>
                    <button @click="openModal()" class="shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-700 transition duration-150 ease-in-out">Delegate Request Approavals</button>
                  </div>
                </div>

                <div class=" flex">
                  <input
                    v-model="filter"
                    type="text"
                    placeholder="Search..."
                    required class="shadow-sm bg-gray-50 text-gray-600 w-full text-size border px-2 py-3 border-gray-300  focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 "/>
                </div>

                <!-- the delegates table -->
                <div class=" overflow-x-auto">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-2 px-2 text-left text-white font-bold">Employee</td>
                        <td class="py-2 px-2 text-left border-l text-white font-bold">Proxy</td>
                        <td class="py-2 px-2 border-l text-left text-white font-bold">Delegation Reason</td>
                        <td class="py-2 px-2 border-l text-left text-white font-bold">Start Date</td>
                        <td class="py-2 px-2 border-l text-left text-white font-bold">End Date</td>
                        <td class="py-2 px-2 border-l text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody v-if="dataLoaded">
                      <tr v-for="(data, i) in filteredRows" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-2 px-2 border text-left">{{ data.firstname }} {{ data.surname }}</td>
                        <td class="py-2 px-2 border text-left">{{ data.proxy_firstname }} {{ data.proxy_surname }}</td>
                        <td class="py-2 px-2 border text-left">{{ data.reason }}</td>
                        <td class="py-2 px-2 border text-left">{{ data.start_date }}</td>
                        <td class="py-2 px-2 border text-left">{{ data.end_date }}</td>

                        <td  class="py-3 px-2 border text-center flex">
                          <div class="flex mx-auto space-x-4">
                            <svg @click="deleteProxy(data.id)" class="h-5 w-5 text-red-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <!-- <svg @click="loadEditUnitBudgetModal(i)" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg> -->
                          </div>
                        </td>


                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="flex mb-5">
                <div class=" flex mx-auto items-center">
                  <div v-if="startRow !== 0" @click="movePages(-1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer hover:bg-emerald-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div v-if="(startRow+rowsPerPage) < employeeProxyData.length" @click="movePages(1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer border-l hover:bg-emerald-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       <!-- the delegates modal -->
       <TransitionRoot appear :show="isOpen" @close="closeModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Delegate Approvals
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                    <!-- employee selection -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Select the Employee <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'employeeId'"
                            :url="'/employee/searchExcept'"
                            :persistedSelection="selectedEmployee"
                            :multipleSelection="false"
                            @selectedItems="(items) => {
                              selectedEmployee = items
                            }"
                            :params="`&id=${exceptionParams}`"
                            :display="{
                              id: 'id',
                              name: ['firstname', 'surname', 'position_name']
                            }"
                          />
                        </div>
                    </div>
                    </div>
                    <!-- employee selection -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Select the Proxy <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'proxyId'"
                            :url="'/employee/searchExcept'"
                            :persistedSelection="selectedEmployees"
                            :multipleSelection="true"
                            @selectedItems="(items) => {
                              splitParameters(items)
                              selectedEmployees = items
                            }"
                            :params="`&id=${selectedEmployee.id}`"
                            :display="{
                              id: 'id',
                              name: ['firstname', 'surname', 'position_name']
                            }"
                          />
                        </div>
                    </div>
                    </div>
                    <!-- employee selection -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Search & Select the Reason <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'reasonId'"
                            :url="'/delegation-reason/search'"
                            :persistedSelection="selectedReason"
                            :multipleSelection="false"
                            @selectedItems="(items) => {
                              selectedReason = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['name']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the cost  -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Start Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="startDate"
                          required
                          placeholder="Item cost"
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- unit quantity -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">End Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="endDate"
                          required
                          placeholder="Item quantity"
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="submitProxy()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
       <!-- Loader modal -->
       <LoadingModal
        ref="loadingModal"
      />
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/AdminNavbar.vue'
  import Sidebar from '@/components/AdminSideBar.vue'
  import { mapState } from 'vuex'
  import { ajax } from 'rxjs/ajax'
  import { retry, zip } from 'rxjs'
  import { ref } from 'vue'
  import LoadingModal from './LoadingModal.vue'
  import MessageModal from './MessageModal.vue'
  import CustomDropDown from './CustomDropDown.vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'

  
  export default {
    computed: {
      ...mapState([
        'id',
        'firstname',
        'surname',
        'token',
      ]),
      filteredRows () {
        return this.employeeProxyData.filter((row) => {
          const searchText = this.filter.toLowerCase()

          return JSON.stringify(row).toLowerCase().includes(searchText)
        }).slice(this.startRow, this.startRow + this.rowsPerPage)
      }
    },
    components: {
      Navbar,
      Sidebar,
      CustomDropDown,
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      LoadingModal,
      MessageModal
    },
    setup () {
      const isOpen = ref(false)
      return {
        isOpen,
        closeModal () {
          isOpen.value = false
        },
        openModal () {
          isOpen.value = true
        }
      }
    },
    name: 'proxy-management',
    props: {
    },
    data: () => {
      return {
        filter: '',
        startRow: 0,
        rowsPerPage: 10,
        endPageItemIndex: 10,
        firstname: '',
        surname: '',
        email: '',
        company: {},
        location: {},
        showProfile: false,
        employeeProxyData: [],
        expandBody: false,
        selectedEmployee: {},
        selectedEmployees: {},
        selectedReason: {},
        dataLoaded: false,
        exceptionParams: '',
        BASE_URL: process.env.VUE_APP_BASE_URL ,
        startDate: '',
        endDate: '',
        isOk: false,
        messageTitle: '',
        message: ''
      }
    },
    mounted () {
      // removing the submenu items selection
      this.$store.commit('mutateActiveListItemIndex', 4)
      this.$store.commit('mutateSelectedSubMenuItem', 3)
      // get the employee details
      this.getEmployeeProxies()
    },
    methods: {
      movePages (amount) {
        var newStartRow = this.startRow + (amount * this.rowsPerPage)
        // only move if its greater than 0
        if (newStartRow >= 0 && newStartRow < this.employeeProxyData.length) {
          this.startRow = newStartRow
        }
      },
      // splits the exception parameters
      splitParameters (items) {
        const data = []
        const size = items.length
        for (let i = 0; i < size; i++) {
          const id = items[i].id
          data.push(id)
        }
        this.exceptionParams = data.toString()
      },
      deleteProxy (id) {
        // close the delegates modal
        this.closeModal()

        // open the loading modal
        this.$refs.loadingModal.openLoaderModal()

        const observable = ajax({
          url: this.BASE_URL + '/proxy/delete?id=' + id,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        const observer = {
          next: () => {
            // this.isOk = true
            // this.messageTitle = 'Message'
            // this.message = 'Successfully deleted the delegation details..'
            // this.$refs.messageModal.openMessageModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to delete the delegation details please try again later...'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
            this.getEmployeeProxies()
          }
        }
        
        observable.subscribe(observer)
      },

      deleteAllProxies () {
        // close the delegates modal
        this.closeModal()

        // open the loading modal
        this.$refs.loadingModal.openLoaderModal()

        const observable = ajax({
          url: this.BASE_URL + '/proxy/delete',
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        const observer = {
          next: () => {
            // this.isOk = true
            // this.messageTitle = 'Message'
            // this.message = 'Successfully deleted the delegation details..'
            // this.$refs.messageModal.openMessageModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to delete the delegation details please try again later...'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
            this.getEmployeeProxies()
          }
        }
        
        observable.subscribe(observer)
      },
      submitProxy () {
        // get the submitted details put them into observables then insert them send them to the back-end
        // get the selected employees then put them into diffrent observables
        if (this.startDate !== '' && this.endDate !== null && this.selectedReason.name !== null && this.selectedReason !== undefined && this.selectedEmployee.id !== undefined && Array.isArray(this.selectedEmployees)) {
          const size = this.selectedEmployees.length
          var observables = []

          // close the delegates modal
          this.closeModal()

          // open the loading modal
          this.$refs.loadingModal.openLoaderModal()

          // generating the observable
          for (let i = 0; i < size; i++) {
            const proxyId = this.selectedEmployees[i].id

            const proxyObservable = ajax({
              url: this.BASE_URL + '/proxy/insert',
              method: 'POST',
              headers: {
                Authorization: `Bearer ${this.token}`
              },
              body: {
                employee_id: this.selectedEmployee.id,
                proxy_id: proxyId,
                reason: this.selectedReason.name,
                start_date: this.startDate,
                end_date: this.endDate
              }
            })

            observables.push(proxyObservable)
          }

          const observable = zip(...observables)

          const observer = {
            next: () => {
              this.isOk = true
              this.messageTitle = 'Message'
              this.message = 'Successfully submitted your delegation details..'
              this.$refs.messageModal.openMessageModal()
            },
            error: () => {
              // close the loader modal
              this.$refs.loadingModal.closeLoaderModal()
              // showing the dialog for the error
              this.isOk = false
              this.messageTitle = 'Error Message'
              this.message = 'Failed to submit your delegation details please try again later...'
              this.$refs.messageModal.openMessageModal()
            },
            complete: () => {
              this.$refs.loadingModal.closeLoaderModal()
              this.getEmployeeProxies()
            }
          }

          observable.subscribe(observer)
        }
      },
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      },
      // getting the employee details
      getEmployeeProxies () {
        this.dataLoaded = false
        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/proxy/findAll',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        }).pipe(
          retry(1)
        )
        const observer = {
          next: (response) => {
            const data = response.response
            this.employeeProxyData = data
            this.dataLoaded = true
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {
            this.showProfile = true
          }
        }
        observable.subscribe(observer)
      }
    }
  }
  </script>
  
  <style>
  
  #chart-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 40vh;
  }
  
  </style>
  