<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
/* .margin {
  margin-left: 19rem;
} */

/* for sm devices */
@media (min-width: 640px) {
  .margin {
    margin-left: 0rem;
  } 
}

/* for md devices */
@media (min-width: 768px) {
  .margin {
    margin-left: 19rem;
  }
}

.text-size {
  font-size: 0.80em;
}

.text-size-login-title {
  font-size: 0.94em;
}

.text-size-md {
  font-size: 0.74em;
}

.text-size-sm {
  font-size: 0.70em;
}

.text-size-xs {
  font-size: 0.66em;
}

.text-blue-main {
  color: #3B82F6;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-align: center;
  color: #2c3e50;
}
</style>
