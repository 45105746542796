<template>
    <!-- <div style="background-image: linear-gradient(to left, rgba(5,129,201,0) 40%,rgba(5,120,87, 0.84) 40%, rgba(5,120,87, 0.84) 30% );" class="w-screen h-screen flex flex-col justify-center "> -->
    <div style="background-image: linear-gradient(to left, rgba(5,129,201,0) 20%,rgba(5,120,87, 0.84) 20%, rgba(5,120,87, 0.84) 60%);" class="w-screen h-screen flex flex-col justify-center ">
    <!-- <div style="" class="w-screen h-screen flex flex-col justify-center bg-gray-100 "> -->
      <!-- The login page card -->
      <div class="relative py-4 sm:max-w-xl sm:mx-auto">
        <div class="relative px-4 py-4 bg-white shadow-2xl border m-2" id="login-card">
          <div class="mx-auto" style="width: 22.8rem">
            <div class="justify-center mb-0.5 pt-10">
              <img src="../assets/egpaf-logo.jpg" class="object-fill pl-4 mx-auto" style="image-rendering: crisp-edges; height: 8.2rem;"/>
            </div>
              <div class="pb-6 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7 ">
                <!-- <p class="font-bold text-size-login-title sm:leading-7 text-emerald-600 opacity-75">WORKFLOW</p> -->
              </div>
              <div class="mb-0 space-y-6 px-5">
                <div class="px-1">
                  <label for="email" class="block text-size font-medium text-gray-600 text-left">Email</label>
                  <div class="mt-1">
                    <input
                      v-model="username"
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      placeholder="Enter Email"
                      @keyup.enter="login"
                      class="w-full border py-3 px-2 text-size shadow-sm focus:ring-emerald-600 focus:ring-2 focus:outline-none bg-gray-100"/>
                  </div>
                </div>
                <div class="px-1">
                  <label for="email" class="block text-size font-medium text-gray-600 text-left">Password</label>
                  <div class="mt-1">
                    <input
                      v-model="password"
                      id="password"
                      name="password"
                      type="password"
                      autocomplete="password"
                      placeholder="Enter Password"
                      @keyup.enter="login"
                      class="w-full border py-3 px-2 text-size shadow-sm focus:ring-emerald-600 focus:ring-2 focus:outline-none bg-gray-100"/>
                  </div>
                </div>
                <div class="px-1 pt-2 pb-6">
                  <button
                    id="box-shadow"
                    @click="login"
                    class="w-full flex justify-center py-3 px-4 border border-transparent  shadow-md text-sm text-size font-medium
                          text-white mb-6 bg-emerald-600 hover:bg-emerald-500  focus:outline-none hover:-translate-y-0.5 transform transition ">
                      Login
                  </button>
                  <div class=" bg-gray-300 h-1 w-full opacity-70" style="height: 1.2px;"></div>
                </div>
              </div>
          </div>
        </div>
      </div>
        <!-- The loader dialog box -->
        <TransitionRoot appear :show="isLoaderOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-40" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full bg-white max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-2xl"
                >
                  <div class="mt-2 justify-center">
                    <div class="flex flex-col items-center">
                        <div class="mt-2">
                        <button
                          type="button"
                          class="text-md text-gray-500 text-sm focus:outline-none focus-visible:ring-2
                                focus-visible:ring-offset-2 focus-visible:ring-white"
                        >
                          Processing please wait....
                        </button>
                        <!--- The login loader -->
                        <span class="loaders"></span>
                      </div>
                    </div>
                    <div class="mt-4 flex justify-center">
                  </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
        </TransitionRoot>
        <!--The error dialog-->
        <TransitionRoot appear :show="isMessageOpen" @close="closeMessageModal" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-40" />
            </TransitionChild>
  
            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
  
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-sm font-medium leading-6 text-gray-900"
                >
                  Login Error
                </DialogTitle>
                <div class="my-3">
                  <p class="text-size text-gray-500">
                    {{errorMessage}}
                  </p>
                </div>
  
                <div class="mt-4 flex justify-center">
                  <button
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                            border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                            focus-visible:ring-offset-2 focus-visible:ring-white outline-none"
                    @click="closeMessageModal"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
        </TransitionRoot>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  import { ajax } from 'rxjs/ajax'
  import { retry } from 'rxjs'
  
  export default {
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay
      // DialogTitle
    },
    setup () {
      const isLoaderOpen = ref(false)
      const isMessageOpen = ref(false)
      return {
        isLoaderOpen,
        isMessageOpen,
        closeLoaderModal () {
          isLoaderOpen.value = false
        },
        openLoaderModal () {
          isLoaderOpen.value = true
        },
        closeMessageModal () {
          isMessageOpen.value = false
        },
        openMessageModal () {
          isMessageOpen.value = true
        }
      }
    },
    name: 'login-component',
    props: {
    },
    data: () => {
      return {
        username: '',
        password: '',
        BASE_URL: process.env.VUE_APP_BASE_URL,
        processing: true,
        errorMessage: ''
      }
    },
    mounted () {
      this.clearState()
    },
    methods: {
      login () {
        if (this.email !== '' && this.password !== '') {
          this.openLoaderModal()
          const observable = ajax({
            url: this.BASE_URL + '/admin/login',
            method: 'POST',
            body: {
              email: this.username,
              password: this.password
            }
          })
          // .pipe(
          //   retry(1)
          // )
  
          const observer = {
            next: (response) => {
              console.log(response)
              const data = response.response[0]
              const id = data.id
              const firstname = data.firstname
              const surname = data.surname
              const position = data.position
              const email = data.email
              const token = data.token
  
              this.$store.commit('mutateId', id)
              this.$store.commit('mutateFirstname', firstname)
              this.$store.commit('mutateSurname', surname)
              this.$store.commit('mutatePosition', position)
              this.$store.commit('mutateEmail', email)
              this.$store.commit('mutateToken', token)
              this.$store.commit('mutateActiveListItemIndex', 0)
  
              // move to the next page
              this.$router.push('/admin-home')
            },
            error: (error) => {
              // // close the loader modal
              this.closeLoaderModal()
              // getting the error
              const status = error.status
              if (status === 401) {
                // try the employee login
                this.employeeLogin()
              } else {
                this.errorMessage = 'Failed to connect to the internet, please check your internet connection...'
                // open the error modal
                this.openMessageModal()
              }
            },
            complete: () => {
              this.closeLoaderModal()
            }
          }
  
          observable.subscribe(observer)
        } else {
          this.errorMessage = 'You cannot login with empty credentials...'
          this.openMessageModal()
        }
      },
      employeeLogin () {
        this.openLoaderModal()
        const observable = ajax({
          url: this.BASE_URL + '/employee/login',
          method: 'POST',
          body: {
            email: this.username,
            password: this.password
          }
        }) .pipe(
            retry(1)
        )

        const observer = {
          next: (response) => {
            const data = response.response[0]
            const id = data.id
            const firstname = data.firstname
            const surname = data.surname
            const position = data.position
            const email = data.email
            const token = data.token
            const officeId = data.office_id
  
            this.$store.commit('mutateId', id)
            this.$store.commit('mutateFirstname', firstname)
            this.$store.commit('mutateSurname', surname)
            this.$store.commit('mutatePosition', position)
            this.$store.commit('mutateEmail', email)
            this.$store.commit('mutateToken', token)
            this.$store.commit('mutateOfficeId', officeId)
            this.$store.commit('mutateActiveListItemIndex', 0)
  
            // record the user login
            this.signin(id, token)
          },
          error: (error) => {
            console.log('error')
            // close the loader modal
            this.closeLoaderModal()
            // getting the error
            const status = error.status
            if (status === 401) {
              this.errorMessage = 'Failed to login, please verify your username and password'
            } else {
              this.errorMessage = 'Failed to connect to the internet, please check your internet connection...'
            }
            // open the error modal
            this.openMessageModal()
          },
          complete: () => {
            this.closeLoaderModal()
          }
        }
  
        observable.subscribe(observer)
      },
      // record the employee sign in
      signin (employeeId, token) {
        const observable = ajax({
          url: this.BASE_URL + '/login/insert',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: {
            employee_id: employeeId
          }
        })

        const observer = {
          next: (response) => {
            const data = response.response
            const loginId = data.insertId
            // store the login id
            this.$store.commit('mutateLoginId', loginId)
          },
          error: () => {
          },
          complete: () => {
            // move to the next page
            this.$router.push('/employee-home')
          }
        }
  
        observable.subscribe(observer)
      },
      clearState () {
        this.$store.commit('mutateId', null)
        this.$store.commit('mutateFirstname', null)
        this.$store.commit('mutateSurname', null)
        this.$store.commit('mutatePosition', null)
        this.$store.commit('mutateEmail', null)
        this.$store.commit('mutateToken', null)
        this.$store.commit('mutateSelectedSubMenuItem', null)
        this.$store.commit('mutateActiveListItemIndex', 0)
        this.$store.commit('mutateSidebarClosed', true)
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
  /* box shadow effect */
  #box-shadow{
    box-shadow: 0 4px 8px 0 rgba(5, 160, 105, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .loaders {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    color: #FFF;
    left: -100px;
    box-sizing: border-box;
    animation: shadowRollings 2s linear infinite;
  }
  
  @keyframes shadowRollings {
    0% {
      box-shadow: 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8);
    }
    12% {
      box-shadow: 100px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8);
    }
    25% {
      box-shadow: 110px 0 rgba(5, 160, 105, 0.8), 100px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8);
    }
    36% {
      box-shadow: 120px 0 rgba(5, 160, 105, 0.8), 110px 0 rgba(5, 160, 105, 0.8), 100px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8);
    }
    50% {
      box-shadow: 130px 0 rgba(5, 160, 105, 0.8), 120px 0 rgba(5, 160, 105, 0.8), 110px 0 rgba(5, 160, 105, 0.8), 100px 0 rgba(5, 160, 105, 0.8);
    }
    62% {
      box-shadow: 200px 0 rgba(5, 160, 105, 0.8), 130px 0 rgba(5, 160, 105, 0.8), 120px 0 rgba(5, 160, 105, 0.8), 110px 0 rgba(5, 160, 105, 0.8);
    }
    75% {
      box-shadow: 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8), 130px 0 rgba(5, 160, 105, 0.8), 120px 0 rgba(5, 160, 105, 0.8);
    }
    87% {
      box-shadow: 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8), 130px 0 rgba(5, 160, 105, 0.8);
    }
    100% {
      box-shadow: 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8);
    }
  }
  </style>
  